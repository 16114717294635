<template>
    <div
        class="ui-list-group-item"
        :class="[
            {
                'ui-list-group-item--active': active && variant !== 'accounts',
                'ui-list-group-item--action': action,
                'ui-list-group-item--active-accounts': active && variant === 'accounts',
            },
            variant ? `ui-list-group-item--${variant}` : '',
        ]"
        @click="$emit('click', $event)"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'ListGroupItem',
    props: {
        active: {
            type: Boolean,
            default: false,
            required: false,
        },
        action: {
            type: Boolean,
            default: false,
            required: false,
        },
        variant: {
            type: String,
            default: null,
            required: false,
        },
    },
};
</script>

<style lang="scss">
.ui-list-group-item {
    border: 1px solid $accounts-secondary-100;
    border-radius: 8px;
    display: block;
    padding: 12px;

    &:not(:last-child) {
        border-bottom: 0;
    }

    &:not(:first-child):not(:last-child) {
        border-radius: 0;
    }

    &:first-child {
        border-radius: 8px 8px 0 0;
    }

    &:last-child {
        border-radius: 0 0 8px 8px;
    }

    &:only-child {
        border-radius: 8px;
    }

    &--active {
        background-color: $accounts-primary-100;
        border-color: $accounts-primary;

        & + .ui-list-group-item {
            border-top-color: $accounts-primary;
        }
    }

    &--action {
        cursor: pointer;
    }
}
</style>
