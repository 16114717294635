<template>
    <span class="ui-badge" :class="`ui-badge--${variant}`">
        <slot></slot>
    </span>
</template>
<script>

export default {
    props: {
        variant: {
            type: String,
            default: 'secondary',
            required: false,
        },
    },
};
</script>

<style lang="scss">
.ui-badge {
    border-radius: 6px;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 150%;
    padding: 2px 4px;
    white-space: nowrap;

    &--primary {
        background-color: rgba($accounts-primary, 0.2);
        color: $accounts-primary;
    }

    &--secondary {
        background-color: rgba($ecart-pay-secondary-300, 0.2);
        color: $ecart-pay-secondary-600;
    }

    &--info {
        background-color: rgba($general-info, 0.2);
        color: $general-info;
    }

    &--success {
        background-color: rgba($general-success, 0.2);
        color: $general-success;
    }

    &--pending {
        background-color: rgba($general-pending, 0.2);
        color: $general-pending;
    }

    &--warning {
        background-color: rgba($general-warning, 0.2);
        color: $general-warning;
    }

    &--danger {
        background-color: rgba($general-error, 0.2);
        color: $general-error;
    }
}
</style>