import popupMixin from '@/plugins/mixin/pop-up.mixin';
import iframeMixin from '@/plugins/mixin/iframe.mixin';

import supportChatUtils from '@/utils/support-chat.utils';

export default {
    mixins: [popupMixin, iframeMixin],
    mounted() {
        this.hideSupportChatWhenEmbed();
    },
    methods: {
        hideSupportChatWhenEmbed() {
            if (this.isPopUp || this.itsAnIframe) {
                supportChatUtils.hideChat();
            }
        },
    },
};