var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-list-group-item",class:[
        {
            'ui-list-group-item--active': _vm.active && _vm.variant !== 'accounts',
            'ui-list-group-item--action': _vm.action,
            'ui-list-group-item--active-accounts': _vm.active && _vm.variant === 'accounts',
        },
        _vm.variant ? `ui-list-group-item--${_vm.variant}` : '',
    ],on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }