<template>
    <component
        class="ui-button"
        v-on="$listeners"
        v-bind="currentBindings"
        :is="tag"
        :class="`ui-button--${variant}`"
        :disabled="this.loading || this.disabled"
    >
        <span v-if="loading" class="ui-button__loader fas fa-spinner fa-spin"></span>
        <slot>
            {{ $t('Continue') }}
        </slot>
    </component>
</template>

<script>
export default {
    name: 'UiButton',
    props: {
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        href: {
            type: String,
            default: null,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        target: {
            type: String,
            default: null,
            required: false,
        },
        to: {
            type: [String, Object],
            default: null,
            required: false,
        },
        type: {
            type: String,
            default: 'button',
            required: false,
        },
        variant: {
            type: String,
            default: 'primary',
            required: false,
        }
    },
    data() {
        return {
            tag: 'button',
            currentBindings: {},
        };
    },
    created() {
        if (this.to) {
            this.tag = 'router-link';
            this.currentBindings = { to: this.to }
        } else if (this.href) {
            this.tag = 'a';
            this.currentBindings = { href: this.href, target: this.target }
        } else {
            this.currentBindings = {
                type: this.type,
            };
        }
    },
};
</script>

<style lang="scss" scoped>
.ui-button {
    align-items: center;
    background-color: $accounts-primary-600;
    border: 1px solid $accounts-primary-600;
    border-radius: 8px;
    color: $white;
    display: inline-flex;
    font-size: 0.875rem;
    font-weight: 500;
    gap: 8px;
    height: 40px;
    justify-content: center;
    line-height: 1;
    padding: 8px 16px;
    text-align: center;
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;

    &:hover {
        background-color: $accounts-primary;
        border-color: $accounts-primary;
        text-decoration: none;
    }

    &:focus {
        background-color: $accounts-primary;
        border-color: $accounts-primary-100;
        outline: 1px solid $accounts-primary-100;
    }

    &:disabled {
        background-color: $accounts-secondary-100;
        border-color: $accounts-secondary-100;
        color: $accounts-secondary-300;
    }

    // Variants
    &--secondary {
        background-color: transparent;
        border-color: $accounts-primary-600;
        color: $accounts-primary-600;
        
        &:hover {
            background-color: $accounts-primary;
            border-color: $accounts-primary;
            color: $white;
        }

        &:focus {
            background-color: $accounts-primary;
            border-color: $accounts-primary-100;
        }

        &:disabled {
            background-color: transparent;
            border-color: $accounts-secondary-100;
            color: $accounts-secondary-300;
        }
    }

    &__loader {
        font-size: 14px;
        margin-right: 2px;
    }
}
</style>
