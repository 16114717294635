export default {
    data() {
        return { window: window }
    },
    computed: {
        itsAnIframe: function () {
            return this.window.self !== this.window.top;
        }
    },
};
