import Vue from 'vue';
import Router from 'vue-router';

import auth from '@/router/auth';
import dashboard from '@/router/dashboard';
import errors from '@/router/errors';
import kyb from '@/router/kyb';
import kyc from '@/router/kyc';
import main from '@/router/main';
import otherRoutes from '@/router/other-routes';
import secondary from '@/router/secondary';
import graybg from '@/router/graybg';
import verification from '@/router/verification';
import profile from '@/router/profile';
import redirect from '@/router/redirect';
import oauthDocusign from '@/router/oauth-docusign';

Vue.use(Router)

const routes = [
  main,
  auth,
  secondary,
  kyc,
  kyb,
  otherRoutes,
  verification,
  dashboard,
  errors,
  graybg,
  profile,
  redirect,
  oauthDocusign
];

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    document.getElementById("app").scrollIntoView({
      block: 'start',
      behavior: "smooth"
    });
  }
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if (to.query.token) {
    localStorage.setItem('token', to.query.token);
  }
  if (to.query.device) {
    localStorage.setItem('device', to.query.device);
  }
  if (to.query.device_token) {
    localStorage.setItem('device_token', to.query.device_token);
  }
  if (to.query.type == "authentication" && (to.query.next == null || to.query.next == undefined)) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    return next({
      path: '/login',
      query: {
        ...to.query,
        next: true
      },
    })
  }
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (localStorage.getItem('token') == null) {
      if (to.query.integration && to.query.token != null) {
        localStorage.setItem('token', to.query.token);
        next();
      } else {
        next({
          path: '/login'
        });
      }
    } else {
      if (user && !user.complete) {
        if (to.query.integration) {
          next();
        } else {
          next({
            path: '/dashboard/account',
            query: {
              integration: true
            },
          })
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
  if (to.matched.some(record => record.meta.newUser)) {
    if (localStorage.getItem('token') == null) {
      next()
    } else {
      next({
        path: '/dashboard'
      })
    }
  }

})


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r?.meta?.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r?.meta?.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = 'Tendencys Accounts | ' + nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;