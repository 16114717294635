<template>
    <div ref="uiSelect" class="ui-new-select">
        <legend class="ui-new-select__label">
            {{ label }}
            <template v-if="isOptional">({{ $t('Optional') }})</template>
        </legend>
        <div class="ui-new-select__input-wrapper" v-click-outside="handleClickOutside">
            <div
                class="ui-new-select__input"
                :class="{
                    'ui-new-select__input--valid': field && field.$dirty && !field.$error,
                    'ui-new-select__input--invalid': field && field.$dirty && field.$error,
                    'ui-new-select__input--disabled': loading || disabled,
                }"
                @click="focusInput"
                @keyup.enter="focusInput"
            >
                <div v-if="selectedOption && selectedOption.image" class="ui-new-select__option-image-container">
                    <img :src="selectedOption.image" :alt="selectedOption[optionLabel]" class="ui-new-select__option-image" />
                </div>
                <div class="ui-new-select__value-container">
                    <div
                        v-if="selectedOption"
                        class="ui-new-select__value"
                        :class="{
                            'ui-new-select__value--focus': value,
                            'ui-new-select__value--has-image': selectedOption && selectedOption.image,
                        }"
                    >
                        {{ selectedOption[this.optionLabel] }}
                    </div>
                    <div v-else class="ui-new-select__placeholder">
                        {{ placeholder }}
                    </div>
                </div>
                <div class="ui-new-select__actions">
                    <button v-if="selectedOption && !disabled" class="ui-new-select__action-button" @click="setValue()">
                        <span class="fas fa-times"></span>
                    </button>
                    <span class="fas fa-chevron-down"></span>
                </div>
            </div>
            <div v-if="showOptions" class="ui-new-select__options">
                <div class="ui-new-select__options-item">
                    <ui-input
                        id="select-search"
                        ref="inputSearch"
                        name="select-search"
                        type="text"
                        placeholder="Search"
                        autocomplete="off"
                        v-model="text"
                    />
                </div>
                <div v-if="!options || !options.length" class="ui-new-select__options-item">
                    No options available
                </div>
                <div v-else-if="!filteredOptions.length" class="ui-new-select__options-item">
                    <p class="mb-0 text-muted">There is no option that matches your search</p>
                </div>
                <div
                    v-for="item in filteredOptions"
                    :key="item[optionValue]"
                    class="ui-new-select__options-item ui-new-select__options-item--clickable"
                    :class="{ 'ui-new-select__options-item--selected': item[optionValue] === value }"
                    @click="setValue(item[optionValue])"
                >
                    <div v-if="item.image" class="ui-new-select__options-item-image-container">
                        <img :src="item.image" :alt="item[optionValue]" class="ui-new-select__options-item-image" />
                    </div>
                    <span class="ui-new-select__options-item-label">
                        {{ item[optionLabel] }}
                    </span>
                </div>
            </div>
        </div>
        <ui-invalid-feedback :field="field" />
    </div>
</template>

<script>
import UiInput from '@/components/ui/inputs/Input.vue';
import UiInvalidFeedback from '@/components/ui/InvalidFeedback.vue';

export default {
    name: 'UiSelect',
    components: { UiInput, UiInvalidFeedback },
    props: {
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        field: {
            type: Object,
            default: null,
            required: false,
        },
        label: {
            type: String,
            default: null,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        optionLabel: {
            type: [Array, String],
            default: 'label',
            required: false,
        },
        options: {
            type: Array,
            default: () => [],
            required: false,
        },
        optionValue: {
            type: String,
            default: 'value',
            required: false,
        },
        placeholder: {
            type: String,
            default: null,
            required: false,
        },
        state: {
            type: Boolean,
            default: null,
            required: false,
        },
        value: {
            type: [String, Number],
            default: null,
            required: false,
        },                
    },
    data() {
        return {
            text: '',
            indexSelect: 0,
            showOptions: false,
        };
    },
    computed: {
        isOptional() {
            return this.field && !this.field?.hasOwnProperty('required');
        },
        selectedOption() {
            return this.options.find(item => item[this.optionValue] === this.value) || null;
        },
        filteredOptions() {
            if (this.text) {
                return this.options.filter(option => {
                    const regex = new RegExp(this.text, 'ig');
                    return option[this.optionLabel].match(regex);
                });
            }

            return this.options;
        },
    },
    mounted() {
        if (this.$attrs.value) {
            this.$emit('input', this.$attrs.value);
            this.$emit('change', this.$attrs.value);
        }
    },
    methods: {
        handleClickOutside() {
            this.showOptions = false;
        },
        focusInput() {
            this.showOptions = true;
            this.text = '';

            setTimeout(() => {
                this.$refs.inputSearch.focus();
            }, 100);
        },
        setValue(value = '') {
            this.$emit('input', value);
            this.$emit('change', value);
            this.handleClickOutside();
        },
    },
};
</script>

<style lang="scss">
.ui-new-select {
    &__label {
        color: $general-black;
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 4px;
    }
    
    &__input-wrapper {
        position: relative;
    }

    &__input {
        align-items: center;
        background-color: $white;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        color: $general-black;
        display: flex;
        font-size: 0.875rem;
        font-weight: 400;
        gap: 4px;
        height: 40px;
        position: relative;
        padding: 8px;
        width: 100%;

        &:focus-within {
            border-color: $ecart-pay-secondary-200;
            box-shadow: none;
        }

        &--disabled {
            color: $accounts-secondary-300;
            background-color: $accounts-secondary-100;
            pointer-events: none;
        }

        &--valid {
            border-color: $general-success;

            &:focus-within {
                border-color: $general-success;
                box-shadow: none;
            }
        }

        &--invalid {
            border-color: $general-error;

            &:focus-within {
                border-color: $general-error;
                box-shadow: none;
            }
        }

        &--has-prepend {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &__value-container {
        align-items: center;
        display: flex;
        height: 100%;
        flex-grow: 1;
        min-width: 0;
    }

    &__placeholder {
        color: $ecart-pay-secondary-300;
        width: 100%;
    }

    &__value {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        &--has-image {
            padding-left: 0;
        }
    }

    &__actions {
        align-items: center;
        display: flex;
        height: 100%;
        flex-shrink: 0;
    }

    &__search-input {
        border: 1px solid $ecart-pay-secondary-200;
        border-radius: 8px;
        padding: 4.5px 8px;
        width: 100%;

        &:focus {
            border-color: #bddb91;
            box-shadow: 0 0 0 0.2rem rgba(#81b23a, 0.25);
        }
    }

    &__options {
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0 8px 16px rgba(#000000, 0.15);
        left: 0;
        margin-top: 4px;
        max-height: 250px;
        overflow-y: auto;
        position: absolute;
        width: 100%;
        z-index: 99;
    }

    &__options-item {
        color: $general-black;
        font-size: 0.875rem;
        padding: 8px;

        &--clickable {
            cursor: pointer;

            &:hover {
                background-color: #F6F6F6;
            }
        }
    }

    &__option-image-container {
        border: 1px solid $ecart-pay-secondary-200;
        border-radius: 6px;
        flex-shrink: 0;
        height: 24px;
        margin-left: 4.5px;
        margin-right: 5px;
        overflow: hidden;
        width: 24px;
    }

    &__option-image {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    &__action-button {
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: 100%;
        color: $general-black;
        display: flex;
        font-size: 0.8rem;
        justify-content: center;
        height: 25px;
        line-height: 1;
        width: 25px;

        &:hover {
            background-color: #F6F6F6;
        }
    }

    &__error {
        color: $general-error;
        font-size: 0.9rem;
        margin-top: 5px;
    }
}
</style>