const ADD_ACCOUNT = (state, payload) => {
    state.accounts = [payload, ...state.accounts];
};

const DELETE_ACCOUNT = (state, payload) => {
    const accounts = [...state.accounts];
    const search_index = accounts.findIndex(item => item.email === payload);

    accounts.splice(search_index, 1);
    state.accounts = [...accounts];
};

const SET_ACCOUNTS = (state, payload) => {
    state.accounts = [...payload];
};

export default {
    ADD_ACCOUNT,
    DELETE_ACCOUNT,
    SET_ACCOUNTS,
};
