import { useView } from "@/functions";

export default {
    path: '*',
    name: 'accounts404',
    component: useView('accounts/404'),
    meta: {
        title: 'Error',
    },
};
