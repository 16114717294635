<template>
    <ui-card class="scopes-register" :title="$t('Add user to the organization')" :description="$t('In order to add a user to the organization, you must enter the following information.')">
        <div>
            <scope-form-skeleton v-if="loading" />
            <template v-else>
                <button variant="light" class="scopes-register__back-button mb-3" @click="goBack()">
                    <em class="fas fa-chevron-left mr-1"></em>
                    {{ $t('Back') }}
                </button>
                <scope-form ref="scopeForm" :companies="companies" :sites="sites" />
                <div class="text-right">
                    <ui-button class="mb-0" v-if="checkScope('write_scopes')" variant="accent" :loading="savingScope" @click="saveScope">
                        {{ $t('Save') }}
                    </ui-button>
                </div>
            </template>
        </div>
    </ui-card>
</template>

<script>
import ScopeForm from '@/components/scopes/Form.vue';
import ScopeFormSkeleton from '@/components/ui/skeletons/ScopeFormSkeleton.vue';
import UiButton from '@/components/ui/buttons/Button.vue';
import UiCard from '@/components/ui/Card.vue';

import scopesMixin from '@/plugins/mixin/scopes.mixin';

export default {
    name: 'ScopesRegister',
    components: {
        ScopeForm,
        ScopeFormSkeleton,
        UiButton,
        UiCard,
    },
    mixins: [scopesMixin],
    data() {
        return {
            companies: [],
            loading: false,
            savingScope: false,
            scopes: [],
            sites: [],
        };
    },
    async mounted() {
        await this.createToken();

        this.getFormOptions();
    },
    methods: {
        goBack() {
            const { company, scope, site } = this.$route.query;
            const query = { scope, site };

            if (company) {
                this.$router.push({ path: `/profile/companies/${company}/scopes`, query });
                return;
            }

            this.$router.push({ path: '/profile/scopes', query });
        },
        async getFormOptions() {
            this. loading = true;

            await this.getCompanies();
            await this.getSites();

            this.loading = false;
        },
        async getCompanies() {
            try {
                const config = {};

                if (this.scopeToken) {
                    config.headers = { Authorization: this.scopeToken };
                }

                const { data } = await this.axiosAccount.get('/api/companies/list', config);

                this.companies = data.docs;
            } catch (error) {
                this.showError(error);
            }
        },
        async getSites() {
            try {
                const config = {};

                if (this.scopeToken) {
                    config.headers = { Authorization: this.scopeToken };
                }
                
                const { data } = await this.axiosAccount.get('/api/sites');

                this.sites = data;
            } catch (error) {
                this.showError(error);
            }
        },
    },
};
</script>

<style lang="scss">
.scopes-register {
    &__add-button {
        background-color: transparent;
        border: 0;
        color: $accounts-accent;
        font-weight: 700;
        margin-top: 10px;
        padding: 8px 0;

        span {
            margin-right: 5px;
        }
    }

    &__back-button {
        background-color: #eeeeee;
        border: none;
        border-radius: 8px;
        padding: 4px 8px;
        transition: 0.15s ease-in-out;

        &:hover {
            background-color: #dbdbdb;
        }
    }
}
</style>
