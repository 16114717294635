<template>
    <ui-list-group-item class="scope-list-item">
        <div class="scope-list-item__container">
            <img
                v-if="siteImage"
                class="scope-list-item__icon-image"
                :src="siteImage"
                :alt="scope.site.name"
                :title="scope.site.name"
            />
            <div v-else class="scope-list-item__icon" :title="scope.site.name">
                <span class="fas fa-globe"></span>
            </div>
            <div class="scope-list-item__details-content">
                <h6 class="scope-list-item__name">
                    {{ scope.account.first_name }} {{ scope.account.last_name }}
                    <ui-badge :variant="scope.role === 'ADMIN' ? 'info' : 'primary'">{{ $t(formatRole(scope.role)) }}</ui-badge>
                </h6>
                <p class="scope-list-item__text">
                    <span class="fas fa-envelope"></span> {{ scope.account.email }}
                </p>
                <p class="scope-list-item__text">
                    <span class="fas fa-phone"></span> {{ scope.account.phone }}
                </p>
                <p class="scope-list-item__text">
                    <span class="fas fa-building"></span> {{ scope.company.name }}
                </p>
            </div>
            <div class="scope-list-item__actions" v-if="checkScope('write_scopes')">
                <button
                    class="scope-list-item__action scope-list-item__action--edit"
                    @click="redirectEdit(scope.id)"
                >
                    <span class="fas fa-edit"></span>
                </button>
                <button
                    class="scope-list-item__action scope-list-item__action--delete"
                    @click="$emit('delete', scope.id)"
                >
                    <span class="fas fa-trash"></span>
                </button>
            </div>
        </div>
    </ui-list-group-item>
</template>

<script>
import UiBadge from '@/components/ui/badges/Badge.vue';
import UiListGroupItem from '@/components/ui/list-group/ListGroupItem.vue';

import scopesMixin from '@/plugins/mixin/scopes.mixin';

export default {
    name: 'ScopeListItem',
    components: {
        UiBadge,
        UiListGroupItem,
    },
    props: {
        scope: {
            type: Object,
            default: () => {},
            required: true,
        },
    },
    mixins: [scopesMixin],
    computed: {
        siteImage() {
            try {
                const site_name = this.scope?.site?.name;

                return require(`@/assets/images/icons/sites/${site_name
                .toLowerCase()
                .replace(/ /g, '-')}.svg`);
            } catch {
                return null;
            }
        },
    },
    async mounted() {
        await this.createToken();
    },
    methods: {
        formatRole(role) {
            return `${role.charAt(0)}${role.slice(1).toLowerCase()}`;
        },
        redirectEdit(scope_id) {
            const { scope, site } = this.$route.query;
            const { id: company } = this.$route.params;

            this.$router.push({ path: `/profile/scopes/${scope_id}/edit`, query: { scope, company, site } });
        },
    }
};
</script>

<style lang="scss">
.scope-list-item {
    &__container {
        align-items: flex-start;
        display: flex;
        gap: 8px;
    }

    &__icon {
        align-items: center;
        background-color: #12b4ba;
        border-radius: 8px;
        color: $white;
        display: flex;
        flex-shrink: 0;
        font-size: 22px;
        height: 50px;
        justify-content: center;
        width: 50px;
    }

    &__icon-image {
        height: 50px;
        width: 50px;
    }

    &__details-content {
        line-height: 150%;
        width: 100%;
    }

    &__name {
        color: $general-black;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1;
        margin-bottom: 4px;
    }

    &__text {
        color: rgba(#212529, 0.6);
        font-weight: 500;
        font-size: 0.9rem;
        margin-bottom: 0;
    }

    &__actions {
        display: flex;
        gap: 4px;
    }

    &__action {
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        color: $accounts-secondary-200;
        display: flex;
        height: 30px;
        justify-content: center;
        transition: 0.15s ease-in-out;
        width: 30px;

        &:hover {
            background-color: #e9e9e9;
        }

        &--edit {
            &:hover {
                background-color: rgba($general-info, 0.1);
                color: $general-info;
            }
        }

        &--delete {
            &:hover {
                background-color: rgba($general-error, 0.1);
                color: $general-error;
            }
        }
    }
}
</style>
