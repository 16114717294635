<template>
    <ui-card
        class="company-scopes-index"
        :title="$t(!loading && !sharedScopes.length && !hasFilters ? 'Add user to the organization' : 'Organization users')"
        :description="$t(!loading && !sharedScopes.length ? 'In order to add a user to the organization, you must enter the following information.' : 'Manage users who have access to this organization.')"
    >
        <button
            variant="light"
            class="company-scopes-index__back-button mb-3"
            @click="goBack()"
        >
            <em class="fas fa-chevron-left mr-1"></em>
            {{ $t("Back") }}
        </button>
        <div v-if="showScopeFilters" class="scope-filters">
            <div class="row">
                <div class="col-md-6 mb-3">
                    <ui-input
                        id="filter"
                        v-model="filters.q"
                        :label="$t('Search account')"
                        :debounce="500"
                        :placeholder="$t('Enter the account name or email')" 
                        name="filter"
                        type="text"
                    />
                </div>
                <div v-if="!$route.query.site" class="col-md-3 mb-3">
                    <ui-select
                        v-model="filters.site_id"
                        :label="$t('Site')"
                        :placeholder="$t('Choose an option')"
                        :options="sites"
                        option-label="name"
                        option-value="id"
                    />
                </div>
                <div class="col-md-3 mb-3">
                    <ui-select
                        v-model="filters.role"
                        :label="$t('Role')"
                        :placeholder="$t('Choose an option')"
                        :options="roles"
                        option-label="name"
                        option-value="key"
                    />
                </div>
            </div>
        </div>
        <list-group-skeleton v-if="loading" :rows="4" />
        <template v-else>
            <div v-if="sharedScopes.length">
                <scope-list :scopes="sharedScopes" @scopeDeleted="scopeDeleted" />
                <div class="text-right" v-if="checkScope('write_scopes')">
                    <button
                        class="company-scopes-index__add-button"
                        @click="redirectRegister()"
                    >
                        <span class="fas fa-plus-circle"></span>
                        {{ $t("Add user") }}
                    </button>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <ui-pagination v-model="filters.page" :total-pages="totalPages" />
                </div>
            </div>
            <div v-else>
                <template v-if="hasFilters">
                    <empty-state
                        class="profile-scopes-index__empty-state"
                        :text="$t(hasFilters ? 'No users were found matching your search parameters.' : 'No users were found in this organization.')"
                        img="empty-box.svg"
                    />
                    <div class="text-right" v-if="checkScope('write_scopes')">
                        <button
                            class="company-scopes-index__add-button"
                            @click="redirectRegister()"
                        >
                            <span class="fas fa-plus-circle"></span>
                            {{ $t("Add user") }}
                        </button>
                    </div>
                </template>
                <template v-else>
                    <scope-form ref="scopeForm" :companies="companies" :sites="sites" />
                    <div class="text-right">
                        <ui-button class="mb-0" v-if="checkScope('write_scopes')" variant="accent" :loading="savingScope" @click="saveScope">
                            {{ $t('Save') }}
                        </ui-button>
                    </div>
                </template>
            </div>
        </template>
    </ui-card>
</template>

<script>
import { mapGetters } from 'vuex';

import EmptyState from '@/components/ui/EmptyState';
import ListGroupSkeleton from '@/components/ui/skeletons/ListGroupSkeleton';
import ScopeForm from '@/components/scopes/Form.vue';
import ScopeList from '@/components/scopes/List.vue';
import UiButton from '@/components/ui/buttons/Button.vue';
import UiCard from '@/components/ui/Card.vue';
import UiInput from '@/components/ui/inputs/Input.vue';
import UiPagination from '@/components/ui/pagination/Pagination';
import UiSelect from '@/components/ui/inputs/Select.vue';

import iframeMixin from '@/plugins/mixin/iframe.mixin';
import scopesMixin from '@/plugins/mixin/scopes.mixin';
import tablesMixin from '@/mixins/tables.mixin';

export default {
    name: 'CompanyScopesIndex',
    components: {
        EmptyState,
        ListGroupSkeleton,
        ScopeForm,
        ScopeList,
        UiButton,
        UiCard,
        UiInput,
        UiPagination,
        UiSelect,
    },
    mixins: [iframeMixin, scopesMixin, tablesMixin],
    data() {
        return {
            oldPage: 1,
            firstLoad: true,
            companies: [],
            loading: false,
            roles: [
                { name: 'Admin', key: 'ADMIN' },
                { name: 'User', key: 'USER' },
            ],
            savingScope: false,
            sharedScopes: [],
            totalPages: 1,
            filters: {
                limit: 4,
                page: 1,
                q: '',
                role: '',
                site_id: '',
            },
        };
    },
    computed: {
        ...mapGetters('ui', ['sites']),
        hasFilters() {
            return this.filters.q || this.filters.role || this.filters.site_id;
        },
        showScopeFilters() {
            if (!this.hasFilters && !this.sharedScopes.length) {
                return false;
            }

            return !this.loading || !this.firstLoad;
        },
    },
    watch: {
        filters: {
            immediate: false,
            handler(value) {
                if (this.oldPage === value.page) {
                    this.filters.page = 1;
                }

                this.oldPage = value.page;

                this.getScopes();
                this.replaceQueryParams(value);
            },
            deep: true,
        },
    },
    async mounted() {
        await this.createToken();

        this.filters = this.getFiltersByQueryParams(this.filters);
    },
    methods: {
        goBack() {
            const { site } = this.$route.query;
            const query = {};

            if (this.itsAnIframe) {
                query.site = site;
            }

            this.$router.push({ path: '/profile/scopes', query });
        },
        async scopeDeleted(id) {
            const search_index = this.sharedScopes.findIndex((item) => item.id === id);

            if (search_index >= 0) {
                this.$delete(this.sharedScopes, search_index);

                if (!this.sharedScopes.length) {
                    this.loading = true;

                    await this.getCompanies();

                    this.loading = false;
                }
            }
        },
        redirectRegister() {
            const { scope, site } = this.$route.query;
            const { id: company } = this.$route.params;

            this.$router.push({ path: '/profile/scopes/register', query: { scope, site, company } });
        },
        async getCompanies() {
            try {
                const config = {};

                if (this.scopeToken) {
                    config.headers = { Authorization: this.scopeToken };
                }

                const { data } = await this.axiosAccount.get('/api/companies');

                this.companies = data.docs;
            } catch (error) {
                this.showError(error);
            }
        },
        async getScopes(filters = null) {
            try {
                this.loading = true;

                const { id: company_id } = this.$route.params;
                const config = { params: { ...this.filters, company_id } };

                if (this.scopeToken) {
                    config.headers = { Authorization: this.scopeToken };
                }
                
                if (filters) {
                    config.params = { ...config.params, ...filters };
                }

                for (const key in config.params) {
                    const value = config.params[key];

                    if (value === '') {
                        delete config.params[key];
                    }  
                }

                const { data } = await this.axiosAccount.get('/api/scopes', config);

                this.sharedScopes = data.docs;
                this.totalPages = data.pages;

                if (!this.hasFilters && !data?.docs?.length) {
                    await this.getCompanies();
                }
            } catch (error) {
                this.showError(error);
            } finally {
                this.loading = false;

                if (this.firstLoad) {
                    this.firstLoad = false;
                }
            }
        },
    },
};
</script>

<style lang="scss">
.company-scopes-index {
    &__add-button {
        background-color: transparent;
        border: 0;
        color: $accounts-primary-600;
        font-weight: 700;
        margin-top: 10px;
        padding: 8px 0;

        span {
            margin-right: 5px;
        }
    }

    &__back-button {
        background-color: #eeeeee;
        border: none;
        width: 100px;
        border-radius: 8px;
        padding: 4px 8px;
        transition: 0.15s ease-in-out;

        &:hover {
            background-color: #dbdbdb;
        }
    }
}
</style>
