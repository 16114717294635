const language = state => state.language;

const languages = state => state.languages;

const loadingSites = state => state.loadingSites;

const sites = state => state.sites;

export default {
    language,
    languages,
    loadingSites,
    sites,
};
