import Vue from 'vue';
import Vuex from 'vuex';

import AccountLogin from './accounts/AccountLogin';
import accounts from './accounts/accounts';
import menu from './modules/menu'
import session from './modules/session'
import ui from '@/store/modules/ui';
import verifications from '@/store/modules/verifications';
import deviceAccounts from '@/store/modules/device-accounts';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    device: null,
    integration: false,
    mobileVersion: false,
    deviceToken: null,
    newDevice: true,
    language: 'en',
    deviceInfomration: {
      id: null,
      token: null,
    },
  },
  modules: {
    AccountLogin,
    accounts,
    menu,
    session,
    ui,
    verifications,
    deviceAccounts
  },
  mutations: {
    setOrdersCount(state, count) {
      state.ordersPendingCount = count;
    },
    setUserSession(state, userSession) {
      state.userSession = userSession;
    },
    setDevice(state, device) {
      state.device = device;
    },
    setDeviceToken(state, deviceToken) {
      state.deviceToken = deviceToken;
    },

    setNewDevice(state, deviceInfomration) {
      if (deviceInfomration.id != null) {
        state.deviceInfomration = {
          ...deviceInfomration
        };
        state.newDevice = false;
      } else {
        state.deviceInfomration = {
          id: null,
          token: null
        };
        state.newDevice = true;
      }

      try {
        UserSession.postMessage(JSON.stringify({
          token: localStorage.getItem('token'),
          user: state.userSession,
        }));
      } catch (err) {
        console.error(err);
      }

    },
    handleResize(state) {
      if (window.innerWidth < 767) {
        state.mobileVersion = true;
      } else {
        state.mobileVersion = false;
      }
    },
    setIntegration(state, integration) {
      state.integration = integration;
    },
    setLanguage(state, lang) {
      state.language = axiosAccount.defaults.headers.common['Accept-Language'] = lang;
    },
  },
  actions: {

    async fnApiDeviceToken({
      state,
      commit
    }) {
      if (state.newDevice && state.device && state.deviceToken) {
        await axios.post(`devices/device`, {
          token: state.deviceToken,
          os: state.device,
        }).then((response) => {
          commit('setNewDevice', response.data);
        }).catch(error => {});
      }
    },

    async globalAction(context) {},

    async fnWSConnect(context) {
      if (context.state.session.user.id && !Vue.prototype.$socket.connected) {
        Vue.prototype.$socket.io.opts.query = {
          seller_id: context.state.session.user.id,
          token: context.state.session.token,
        }
        await Vue.prototype.$socket.open();
      }
    },
    async fnWSDisconnect() {
      await Vue.prototype.$socket.close();
    },
  },
  // strict: process.env.NODE_ENV !== 'production',
});