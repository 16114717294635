import actions from '@/store/modules/device-accounts/actions';
import getters from '@/store/modules/device-accounts/getters';
import mutations from '@/store/modules/device-accounts/mutations';
import state from '@/store/modules/device-accounts/state';

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
