import Vue from 'vue';
import mixin from './mixin'
Vue.mixin(mixin);

Vue.prototype.$lodash = require('./lodash');
import moment from 'moment';
Object.defineProperty(Vue.prototype, '$moment', {
    value: moment
});

Vue.component('no-data', () => import('@/components/Loader'));
// Vue.component('Message', () => import('@/components/message'));
// Vue.component('float-container', () => import('@/components/FloatContainer'));
// Vue.component('product-card', () => import('@/components/ProductCard'));
// External
// Vue.component('v-date-picker', () => import('vue-ctk-date-time-picker'));
// Vue.use(() => import('vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'));

// Vue.component('v-tree', () => import('@riophae/vue-treeselect'));
// Vue.use(() => import('@riophae/vue-treeselect/dist/vue-treeselect.css'));
// Vue.component('v-aos', () => import('vue-aos'));


// Bootstrap
import { BootstrapVue } from 'bootstrap-vue';
Vue.use(BootstrapVue); 