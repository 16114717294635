<template>
    <div class="scope-form">
        <p class="scope-form__section-subtitle">
            <span class="fas fa-user"></span>
            {{ $t('Related account') }}
        </p>
        <div class="mb-3">
            <account-finder v-if="!scope && !selectedAccount" @select="selectAccount" />
            <ui-invalid-feedback :field="v$.formData.email" />
            <div v-if="selectedAccount" class="scope-form__account mt-3">
                <div>
                    <avatar v-if="selectedAccount.first_name" :name="selectedAccount.first_name" :lastName="selectedAccount.last_name" size="xs" />
                    <div v-else class="scope-form__account-icon">
                        <span class="fas fa-envelope"></span>
                    </div>
                </div>
                <div class="scope-form__account-content">
                    <h6 class="mb-0">
                        {{ selectedAccount.first_name ? `${selectedAccount.first_name} ${selectedAccount.last_name}` : selectedAccount.email }}
                    </h6>
                    <p class="mb-0 text-muted">
                        <template v-if="selectedAccount.first_name">
                            {{ selectedAccount.email }}
                        </template>
                        <template v-else>
                            <span class="fas fa-info-circle"></span>
                            {{ $t('Email not registered on the platform.') }}
                        </template>
                    </p>
                </div>
                <button v-if="!scope" class="scope-form__action scope-form__action--delete" @click="deselectAccount">
                    <span class="fas fa-trash"></span>
                </button>
            </div>
        </div>
        <p class="scope-form__section-subtitle">
            <span class="fas fa-list-alt"></span>
            {{ $t('Scope details') }}
        </p>
        <div class="row">
            <div class="col-md-4 mb-3">
                <ui-select
                    v-if="!defaultCompany"
                    v-model="formData.company_id"
                    :label="$t('Organization')"
                    :placeholder="$t('Choose an option')"
                    :options="companies"
                    :field="v$.formData.company_id"
                    option-label="name"
                    option-value="id"
                    :disabled="!!scope"
                />
                <ui-input
                    v-else
                    id="company"
                    name="company"
                    :value="defaultCompany.name"
                    :label="$t('Organization')"
                    type="text"
                    disabled
                />
            </div>
            <div class="col-md-4 mb-3">
                <ui-select
                    v-if="!defaultSite"
                    v-model="formData.site_id"
                    :label="$t('Site')"
                    :placeholder="$t('Choose an option')"
                    :options="filteredSites"
                    :field="v$.formData.site_id"
                    option-label="name"
                    option-value="id"
                    :disabled="!!scope"
                />
                <ui-input
                    v-else
                    id="site"
                    name="site"
                    :value="defaultSite.name"
                    :label="$t('Site')"
                    type="text"
                    disabled
                />
            </div>
            <div class="col-md-4 mb-3">
                <ui-select
                    v-model="formData.role"
                    :label="$t('Role')"
                    :placeholder="$t('Choose an option')"
                    :options="roles"
                    :field="v$.formData.role"
                    option-label="name"
                    option-value="key"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators';

import AccountFinder from '@/components/ui/account-finder/AccountFinder.vue';
import Avatar from '@/components/ui/Avatar.vue'
import UiInvalidFeedback from '@/components/ui/InvalidFeedback.vue';
import UiSelect from '@/components/ui/inputs/Select.vue';
import UiInput from '@/components/ui/inputs/Input.vue';

export default {
    name: 'ScopeForm',
    components: {
        AccountFinder,
        Avatar,
        UiInvalidFeedback,
        UiInput,
        UiSelect,
    },
    props: {
        companies: {
            type: Array,
            default: () => [],
            required: false,
        },
        scope: {
            type: Object,
            default: null,
            required: false,
        },
        sites: {
            type: Array,
            default: () => [],
            required: false,
        },
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            selectedAccount: null,
            addingAddress: false,
            formData: {
                company_id: '',
                email: '',
                role: '',
                site_id: '',
            },
            roles: [
                { name: 'Admin', key: 'ADMIN' },
                { name: 'User', key: 'USER' },
            ],
        };
    },
    computed: {
        defaultCompany() {
            const company = this.scope?.company;

            if (company) {
                return company;
            }

            const { company: company_query } = this.$route.query;
            
            if (company_query) {
                return this.companies.find(company => company.id === company_query);
            }

            if (this.$route?.name === 'scopes-company') {
                return this.companies.find(company => company.id === this.$route.params.id);
            }

            return null;
        },
        defaultSite() {
            const site = this.scope?.site;
            
            if (site) {
                return site;
            }
            
            const { site: site_query } = this.$route.query;

            if (site_query) {
                return this.sites.find(site => site.id === site_query);
            }

            return null;
        },
        filteredSites() {
            return this.sites.filter(site => site.name === 'Ecart Pay');
        },
        rules() {
            const rules = {
                formData: {
                    company_id: {
                        required: helpers.withMessage(this.$t('validations.required.female', { field: this.$t('company') }), required),
                    },
                    email: {
                        required: helpers.withMessage(this.$t('validations.required', { field: this.$t('email') }), required),
                    },
                    role: {
                        required: helpers.withMessage(this.$t('validations.required', { field: this.$t('role') }), required),
                    },
                    site_id: {
                        required: helpers.withMessage(this.$t('validations.required', { field: this.$t('site') }), required),
                    },
                },
            };

            return rules;
        },
    },
    validations() {
        return this.rules;
    },
    mounted() {
        this.setDefaultValues();
    },
    methods: {
        validate() {
            this.v$.$touch();
            
            const address_form = this.$refs.addressForm;

            if (address_form) {
                return address_form.validate() && !this.v$.$invalid;
            }

            return !this.v$.$invalid;
        },
        setDefaultValues() {
            const { id: company_id } = this.$route.params;
            const { company: company_query, site: site_query } = this.$route.query;

            if (company_id) {
                this.formData.company_id = company_id;
            }

            if (company_query) {
                this.formData.company_id = company_query;
            }

            if (site_query) {
                this.formData.site_id = site_query;
            }

            if (!this.scope) {
                if (this.companies.length === 1) {
                    this.formData.company_id = this.companies[0].id;
                }
                if (this.filteredSites.length === 1) {
                    this.formData.site_id = this.filteredSites[0].id;
                }
                return;
            }

            const { account, company, role, site } = this.scope;

            this.formData.company_id = company.id;
            this.formData.email = account.email;
            this.formData.role = role;
            this.formData.site_id = site.id;
            this.selectedAccount = account;
        },
        selectAccount(account) {
            this.selectedAccount = account;
            this.formData.email = account.email;
        },
        deselectAccount() {
            this.selectedAccount = null;
            this.formData.email = '';
        },
        getFormData() {
            const form_data = { ...this.formData };

            if (this.scope) {
                return { role: form_data.role };
            }

            return form_data;
        },
    },
};
</script>

<style lang="scss">
.scope-form {
    &__section-subtitle {
        color: $accounts-secondary-400;
    }

    &__address-list {
        &--invalid {
            .ui-list-group-item {
                border-color: $general-error;
            }
        }
    }

    &__account {
        align-items: center;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        padding: 16px;
    }

    &__account-icon {
        align-items: center;
        background-color: $accounts-primary;
        border-radius: 100%;
        color: $white;
        display: flex;
        font-size: 1rem;
        height: 40px;
        justify-content: center;
        width: 40px;
    }

    &__account-content {
        width: 100%
    }

    &__account-delete-button {
        align-items: center;
        background-color: transparent;
        border: 1px solid #12B4BA;
        border-radius: 8px;
        color: #12B4BA;
        display: flex;
        flex-shrink: 0;
        gap: 8px;
        height: 32px;
        padding: 8px 16px;

        &:hover {
            background-color: #12B4BA;
            color: $white;
        }
    }

    &__action {
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        color: $accounts-secondary-200;
        display: flex;
        height: 30px;
        justify-content: center;
        transition: 0.15s ease-in-out;
        width: 30px;

        &--delete {
            &:hover {
                background-color: rgba($general-error, 0.1);
                color: $general-error;
            }
        }
    }
}
</style>
