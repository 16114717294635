import router from "@/router";
import {
  EcUserModel
} from "@/plugins/beans.js";

const state = {
  user: EcUserModel(),
  token: null,
  modals: {
    recoveryPassword: false,
  },
  loading: {
    data: false,
    oauth: false,
    oauthToken: false,
  },
  showAlert: {
    pasword: false,
    account: false,
    session: false,
    email: false,
  },
  billingCountries: ['MX'],
  oauth: {
    app_id: null,
    hash: null,
    name: null,
    redirect_uri: null,
    code: null,
    type: null,
  },
};

// getters
const getters = {
  needBilling(state) {
    return state.billingCountries.includes(state.user.country);
  }
};
// mutations
const mutations = {
  setSession(state, session) {
    state.user = session;
    localStorage.setItem("user", JSON.stringify(session));
  },
  setOauth(state, oauth) {
    state.oauth = {
      ...state.oauth,
      ...oauth
    };
  },
  setToken(state, token) {
    state.token = token;
  },
  changeLoading(state, status) {
    state.loading = {
      ...state.loading,
      ...status
    };
  },
  changeAlert(state, status) {
    state.showAlert = {
      ...state.showAlert,
      ...status
    };
  },
  changeModal(state, status) {
    state.modals = {
      ...state.modals,
      ...status
    };
  },
  readLocalSession(state) {
    let tempUser = JSON.parse(localStorage.getItem('user'));
    if (tempUser && tempUser.id) {
      state.user = tempUser;
    }
  },
};
// actions
const actions = {
  async fnGetUserInfo(context) {
    if (localStorage.getItem('device') != undefined) {
      context.commit('setDevice', localStorage.getItem('device'), {
        root: true
      });
    }
    if (localStorage.getItem('device_token') != undefined) {
      context.commit('setDeviceToken', localStorage.getItem('device_token'), {
        root: true
      });
    }

    if (localStorage.getItem('token') != undefined) {
      context.commit("setToken", localStorage.getItem('token'));
      await axios.get("me").then(async response => {
        if (response.data.language) {
          context.commit('setLanguage', response.data.language, {
            root: true
          });
        }
        localStorage.setItem("user", JSON.stringify(response.data));
        context.commit("setSession", response.data);
        context.commit('setUserSession', response.data, {
          root: true
        });
        await context.dispatch('fnWSConnect', null, {
          root: true
        });
        await context.dispatch('fnApiDeviceToken', null, {
          root: true
        });
        
      });
    }


  },
  async fnApiOauth(context, query) {
    context.commit("changeLoading", {
      oauth: true,
    });
    await axiosOauth.get(`oauth/authorization`, {
        params: {
          app_id: query.app_id,
          type: query.type,
          redirect_uri: query.redirect_uri
        }
      })
      .then(response => {
        context.state.oauth.code = response.data.code;
        context.state.oauth.name = response.data.name;
        context.state.oauth.app_id = query.app_id;
        context.state.oauth.redirect_uri = query.redirect_uri;
      })
      .catch(error => {});

    context.commit("changeLoading", {
      oauth: false,
    });
  },
  async fnApiOauthToken(context) {
    if (context.state.oauth.redirect_uri) {
      window.location.href = atob(context.state.oauth.redirect_uri);
    }
  },

  async fnApiLogin(context, userData) {
    let {
      email,
      password,
      redirectTo,
      query,
    } = userData;

    let tempUser = {
      email,
      password
    };

    if (query.hash) {
      tempUser.hash = query.hash;
    }

    context.commit("changeLoading", {
      data: true
    });
    await axios
      .post("login", tempUser)
      .then(async response => {
        localStorage.setItem("token", response.data.token);
        await context.dispatch("fnGetUserInfo");
        if (query != undefined && query.type == "authentication") {
          return router.push({
              path: '/authorization',
              query,
            },
            () => {}
          );
        }
        if (redirectTo) {
          return router.push({
              path: redirectTo
            },
            () => {}
          );
        } else {
          return router.push({
              path: "/dashboard/dashboard"
            },
            () => {}
          );
        }

      })
      .catch(error => {
        switch (error.response && error.response.status) {
          case 401:
            context.commit("changeAlert", {
              password: true
            });
            break;
          case 403:
            context.commit("changeAlert", {
              account: true
            });
            break;
        }
      });
    context.commit("changeLoading", {
      data: false
    });
  },

  async fnApiSignup(context, userData) {
    context.commit('changeLoading', {
      data: true,
    });
    await axios.post('register', userData).then(async response => {
      router.push({
        path: `/messages?email=${userData.email}&type=signup`
      }, () => {});
    }).catch(error => {
      if (error.response.data.statusCode == 422) {
        context.commit("changeAlert", {
          email: true
        });
      }
    });
    context.commit('changeLoading', {
      data: false,
    });
  },

  async fnLogout(context, link) {
    axios.get(`${process.env.VUE_APP_ACCOUNTS}&logout=true`).then(response => {}).catch(error => {});
    if (context.rootState.deviceInfomration.id) {
      await axios.delete(`devices/device/${context.rootState.deviceInfomration.id}`).then(response => {}).catch(error => {});
      try {
        UserSession.postMessage(JSON.stringify({
          token: null,
          user: null,
        }));
      } catch (err) {
        console.log(err);
      }
    }

    localStorage.removeItem("user");
    localStorage.removeItem("token");

    context.commit('setIntegration', false, {
      root: true
    });

    context.commit("setSession", {
      id: null
    });
    sessionStorage.removeItem("hideMenu");
    if (link) {
      router.push(link);
    } else {
      router.push("/");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};