<template>
    <div
        class="ui-skeleton"
        :class="`ui-skeleton--${type}`"
        :style="{ height: height, width: width }"
    ></div>
</template>

<script>
export default {
    name: 'Skeleton',
    props: {
        height: {
            type: Number,
            default: null,
            required: false,
        },
        type: {
            type: String,
            default: 'text',
            required: false,
        },
        width: {
            type: Number,
            default: null,
            required: false,
        },
    },
};
</script>

<style lang="scss">
.ui-skeleton {
    position: relative;
    overflow: hidden;
    background-color: rgba(0,0,0,.12);
    cursor: wait;
    mask-image: radial-gradient(#fff,#000);
    height: 18px;
    margin-bottom: 0.25rem;
    border-radius: 8px;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        background: linear-gradient(90deg,transparent,hsla(0,0%,100%,.4),transparent);
        animation: skeleton-animate-wave 1.75s linear infinite;
    }

    &--input{
        height: 40px;
        width: 100%;
    }

    @keyframes skeleton-animate-wave {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(100%);
        }
    }
}
</style>
