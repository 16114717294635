import vClickOutside from 'v-click-outside';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import GSignInButton from 'vue-google-signin-button';
import VueTheMask from 'vue-the-mask';

import App from './App.vue';
import i18n from './i18n';
import './plugins';
import router from './router';
import store from './store';
import '@/config';

// App styles
Vue.use(() => import('@/assets/scss/styles.scss'));

Vue.use(VueTheMask);
Vue.use(GSignInButton);
Vue.use(VueCookies);
Vue.use(vClickOutside);

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')