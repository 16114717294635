<template>
    <div class="two-factor-authentication-card">
        <div class="two-factor-authentication-card__header">
            <div class="text-right">
                <button class="two-factor-authentication-card__button" @click="$refs.deviceList.openAddDeviceModal()">
                    {{ $t('Add') }}
                    <span class="fas fa-plus"></span>
                </button>
            </div>
            <div class="two-factor-authentication-card__content">
                <div class="two-factor-authentication-card__icon">
                    <span class="fas fa-qrcode"></span>
                </div>
                <div class="two-factor-authentication-card__text-container">
                    <h6 class="mb-0">{{ $t('Two factor authentication') }}</h6>
                    <p class="mb-0">{{ $t('Add an extra layer of security to your account by requesting access with your smartphone when you login.') }}</p>
                </div>
            </div>
        </div>
        <div class="two-factor-authentication-card__content" >
            <device-list ref="deviceList" class="two-factor-authentication-card__device-list" />
        </div>
    </div>
</template>

<script>
import DeviceList from '@/components/authentication/two-factor/DeviceList.vue';

export default {
    name: 'TwoFactorAuthenticationCard',
    components: { DeviceList },
    data () {
        return {
            loading: false,
        }
    }
}
</script>

<style lang="scss">
.two-factor-authentication-card {
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;

    &__header {
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: space-between;
        width: 100%;
        
        @media (min-width: 768px) {
            flex-direction: row-reverse;
            gap: 32px;
        }
    }

    &__content {
        display: flex;
        gap: 8px;
        width: 100%;
    }

    &__icon {
        align-items: center;
        background-color: #71D2D6;
        border-radius: 8px;
        color: $white;
        display: flex;
        flex-shrink: 0;
        font-size: 1rem;
        height: 30px;
        justify-content: center;
        width: 30px;

        @media (min-width: 768px) {
            height: 50px;
            width: 50px;
            font-size: 1.375rem;
        }
    }

    &__button {
        align-items: center;
        background-color: transparent;
        border: 1px solid #12B4BA;
        border-radius: 8px;
        color: #12B4BA;
        display: inline-flex;
        flex-shrink: 0;
        gap: 8px;
        height: 32px;
        padding: 8px 16px;

        &:hover {
            background-color: #12B4BA;
            color: $white;
        }
    }

    &__empty-message {
        background-color: #eff1f8;
        border-radius: 8px;
        padding: 16px;
        text-align: center;
        width: 100%;
        margin-bottom: 0;
    }

    &__device-list {
        border-radius: 8px;
        padding: 16px;
        text-align: center;
        width: 100%;
        margin-bottom: 0;
    }
}
</style>
