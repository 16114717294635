<template>
    <nav v-if="totalPages > 1" class="ui-pagination" aria-label="navigation">
        <ul class="ui-pagination__list">
            <li class="ui-pagination__item">
                <button
                    class="ui-pagination__link ui-pagination__link--arrow"
                    :class="value === 1 && 'ui-pagination__link--disabled'"
                    aria-label="Previous"
                    @click="changePage(value - 1)"
                >
                    <span class="fas fa-arrow-left"></span>
                </button>
            </li>
            <template v-if="value - 1 > 0">
                <li
                    v-for="i in 1"
                    :key="`item-${i}-previous`" class="ui-pagination__item"
                    @click="changePage(value - i)"
                >
                    <button class="ui-pagination__link" :aria-posinset="value">
                        {{ value - i }}
                    </button>
                </li>

            </template>
            <li class="ui-pagination__item">
                <span class="ui-pagination__link ui-pagination__link--active" :aria-posinset="value">
                    {{ value }}
                </span>
            </li>
            <template v-if="value < totalPages">
              <li v-for="i in 1" :key="`item-${i}-next`" class="ui-pagination__item" @click="changePage(i + value)">
                  <button class="ui-pagination__link" :aria-posinset="value">
                      {{ i + value }}
                  </button>
              </li>
            </template>
            <li class="ui-pagination__item">
                <button
                    class="ui-pagination__link ui-pagination__link--arrow"
                    aria-label="Next"
                    @click="changePage(value + 1)"
                    :class="value === totalPages && 'ui-pagination__link--disabled'"
                >
                    <span class="fas fa-arrow-right"></span>
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'UiPagination',
    props: {
        value: {
            type: Number,
            default: 1,
        },
        totalPages: {
            type: Number,
            default: 1,
        },
    },
    methods: {
        changePage(page) {
            this.$emit('input', page);
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-pagination {
    &__list {
        align-items: center;
        display: flex;
        gap: 4px;
        list-style: none;
        margin-bottom: 0;
        padding: 0;
    }

    &__link {
        align-items: center;
        background-color: transparent;
        border: 1px solid $accounts-primary;
        border-radius: 8px;
        color: $accounts-primary;
        display: inline-flex;
        font-size: 0.875rem;
        font-weight: 400;
        height: 32px;
        gap: 4px;
        justify-content: center;
        line-height: 110%;
        min-width: 32px;
        padding: 4px;
        transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out;

        &:hover {
            background-color: $accounts-primary;
            color: $white;
            outline: 1px solid $accounts-primary-100;
        }

        &--disabled {
            pointer-events: none;
            color: $accounts-primary-100;
            border-color: $accounts-primary-100;
        }

        &--active {
            background-color: $accounts-primary;
            color: #ffffff;

            &:hover {
                background-color: $accounts-primary;
            }
        }

        &--arrow {
            font-size: 0.7rem;
        }
    }
}
</style>
