import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en-US',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
});

import axios from 'axios';
axios.get(`${process.env.VUE_APP_HOSTNAME}/languages.json`)
.then(response => response.data)
.then(languages => {
    Object.keys(languages).forEach(attr => {
        i18n.setLocaleMessage(attr, languages[attr]);
    });
})
.catch(err => {
  throw err
});

export default i18n;
