<template>
    <div class="auth-list">
        <div class="auth-list__information">
            <h6 class="auth-list__title">
                <span class="auth-list__title-icon fas fa-mobile-alt"></span>
                {{  auth.name }}
            </h6>
            <p class="text-muted">
                {{ $t('Last updated') }}: {{ this.date_format}}
            </p>
        </div>
        <b-button class="auth-list__button" @click="$emit('deleteApp', auth)">
            <span class="auth-list__button-icon fas fa-trash"></span>
        </b-button>
    </div>
</template>

<script>

export default {
    name: 'DeviceItem',
    data() {
        return {
            date_format: '',
        };
    },
    props: {
        auth: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        this.formatDate();
    },
    methods: {
        formatDate() {
            this.date_format = new Date(this.auth.remind_me_at).toLocaleString();
            this.date_format = this.date_format.slice(0, -3);
        }
    }
}
</script>

<style lang="scss">
.auth-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;

    &__information {
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    &__title {
        color: $general-black;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0;

        span {
            color: $accounts-secondary-200;
            font-size: 1rem;
        }
    }

    &__button {
        background-color: transparent !important;
        border: none !important;

        span {
            color: $accounts-secondary-200 !important;
            font-size: 1rem !important;
        }
    }

    &__description {
        font-size: 0.8rem;
        margin-bottom: 0;
    }
}
</style>