const languageCodes = [
    'en-AU',
    'en-CA',
    'en-GB',
    'en-IN',
    'en-US',
    'en-ZA',
    'es-AR',
    'es-CL',
    'es-CO',
    'es-ES',
    'es-GT',
    'es-MX',
    'es-PE',
    'es-PR',
    'es-UY',
    'fr-FR',
    'hi-IN',
    'it-IT',
    'pt-BR',
    'pt-PT',
    'ru-RU',
    'zh-CN',
];

export default languageCodes;
