<template>
    <div class="scopes-companies-list">
        <ui-list-group-item
            v-for="company in companies"
            :key="company.id"
            class="scopes-companies-list__item"
            @click="redirect(company)"
        >
            <div class="scopes-companies-list__container">
                <img
                    class="scopes-companies-list__icon-image"
                    :src="siteImage(company)"
                    :alt="company.name"
                    :title="company.name"
                />
                <div class="scopes-companies-list__details-content">
                    <h6 class="scopes-companies-list__name">{{ company.name }}</h6>
                    <p class="scopes-companies-list__text">
                        <span class="fas fa-building"></span>
                        {{ company.email }}
                    </p>
                </div>
                <div class="scopes-companies-list__actions">
                    <span class="fas fa-chevron-right"></span>
                </div>
            </div>
        </ui-list-group-item>
    </div>
</template>

<script>
import UiListGroupItem from '@/components/ui/list-group/ListGroupItem.vue';

export default {
    name: 'CompaniesList',
    components: { UiListGroupItem },
    props: {
        companies: {
            type: Array,
            default: () => [],
            required: false,
        },
        external: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    methods: {
        redirect(company) {
            let site = this.$route?.query?.site || company?.site?.id;
            const query = { scope: company.scope_id, site };

            return this.$router.push({ path: `/profile/companies/${company.id}/scopes`, query});
        },
        siteImage(company) {
            try {
                let icon_name = 'company-icon';
                
                if (this.external) {
                    const site_name = company.site?.name;
                    
                    if (site_name) {
                        return require(`@/assets/images/icons/sites/${site_name.toLowerCase().replace(/ /g, '-')}.svg`);
                    }

                    icon_name = 'company-icon-primary'
                }

                return require(`@/assets/images/icons/${icon_name}.svg`);
            } catch {
                return null;
            }
        },
    },
};
</script>


<style lang="scss">
.scopes-companies-list {
    &__item {
        cursor: pointer;
    }

    &__container {
        align-items: center;
        display: flex;
        gap: 8px;
    }

    &__icon {
        align-items: center;
        background-color: #12b4ba;
        border-radius: 8px;
        color: $white;
        display: flex;
        flex-shrink: 0;
        font-size: 22px;
        height: 50px;
        justify-content: center;
        width: 50px;
    }

    &__icon-image {
        height: 50px;
        width: 50px;
    }

    &__details-content {
        line-height: 150%;
        width: 100%;
    }

    &__name {
        color: $general-black;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1;
        margin-bottom: 4px;
    }

    &__text {
        color: rgba(#212529, 0.6);
        font-weight: 500;
        font-size: 0.9rem;
        margin-bottom: 0;
    }

    &__actions {
        color: $accounts-secondary-200;
        margin-right: 8px;
    }
}
</style>
