import { filterScope } from '@/utils/verifyScopes.utils';

export default {
    data() {
        return {
            creatingToken: true,
            scopeToken: null,
        };
    },
    methods: {
        async getScopeToken() {
            const { scope } = this.$route.query;

            if (scope) {
                const { data } = await this.axiosAccount.post('/api/scopes/authorization', { scope_id: scope });
                return data.token;
            }

            return null;
        },
        async checkScope(scope_name) {
            if (this.scopeToken) {
                return filterScope(this.scopeToken, scope_name);
            }

            return true;
        },
        async saveScope() {
            try {
                this.savingScope = true;
    
                if (!this.$refs.scopeForm.validate()) {
                    return;
                }
                
                const config = {};
                const token = await this.getScopeToken();

                if (token) {
                    config.headers = { Authorization: token };
                }

                const formData = { ...this.$refs.scopeForm.getFormData() };
                const { data } = await this.axiosAccount.post('/api/scopes', formData, config);

                const { company, scope, site } = this.$route.query;
                const query = { company, scope, site };

                if (this.$route?.name === 'scopes-company') {
                    query.company = this.$route.params.id;
                }

                this.$router.push({ path: `/profile/scopes/${data.id}/edit`, query });
                this.$toasted.global.successMessage(this.$t('Successfully shared scope'));
            } catch (error) {
                this.showError(error);
            } finally {
                this.savingScope = false;
            }
        },

        async createToken() {
            try {
                this.creatingToken = true;
                
                const { scope: scope_id } = this.$route.query;

                if (!scope_id) {
                    this.scopeToken = null;
                    return;
                }

                let scope_tokens =  sessionStorage.getItem('scopes_tkl');

                if (scope_tokens) {
                    scope_tokens = JSON.parse(scope_tokens);

                    const scope_token = scope_tokens.find(item => item.id === scope_id);
                    
                    if (scope_token) {
                        const expiration_hours = this.$moment.duration(this.$moment(scope_token.expiration).diff(this.$moment())).asHours();
                        
                        if (expiration_hours > 1) {
                            this.scopeToken = scope_token.token;
                            return;
                        }

                        const token_index = scope_tokens.findIndex(item => item.id === scope_token.id);

                        if (token_index >= 0) {
                            scope_tokens.splice(token_index, 1);
                        }
                    }
                } else {
                    scope_tokens = [];
                }

                const { data } = await this.axiosAccount.post('/api/scopes/authorization', { scope_id: scope_id });

                scope_tokens.push({
                    id: scope_id,
                    token: data.token,
                    expiration: this.$moment().add(12, 'hours'),
                });

                sessionStorage.setItem('scopes_tkl', JSON.stringify(scope_tokens));

                this.scopeToken = data.token;
            } catch (error) {
                this.showError(error);
            } finally {
                this.creatingToken = false;
            }
        },
    },
};
