<template>
    <ui-card class="dashboard-profile-card">
        <div class="dashboard-profile-card__container">
            <ul class="dashboard-profile-card__nav">
                <li class="dashboard-profile-card__nav-item">
                    <router-link class="dashboard-profile-card__nav-link" active-class="dashboard-profile-card__nav-link--active" to="/dashboard/profile" exact>
                        {{ $t('My profile') }}
                    </router-link>
                </li>
                <li class="dashboard-profile-card__nav-item">
                    <router-link class="dashboard-profile-card__nav-link" active-class="dashboard-profile-card__nav-link--active" to="/dashboard/profile/security" exact>
                        {{ $t('Security') }}
                    </router-link>
                </li>
            </ul>
            <div class="dashboard-profile-card__content">
                <slot></slot>
            </div>
        </div>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';

export default {
    name: 'DashboardProfileCard',
    components: {
        UiCard,
    },
};
</script>

<style lang="scss">
.dashboard-profile-card {
    &__container {
        display: flex;
        gap: 24px;
        flex-direction: column;

        @media(min-width: 993px) {
            flex-direction: initial;
        }

    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    &__nav {
        display: flex;
        flex-shrink: 0;
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        
        @media(min-width: 993px) {
            border-right: 1px solid #D9D9D9;
            flex-direction: column;
            gap: 12px;
            width: 130px;
        }
    }

    &__nav-item {
        width: 100%;

        @media(min-width: 993px) {
            align-self: flex-start;
            width: initial;
        }
    }

    &__nav-link {
        align-items: center;
        background-color: transparent;
        border-radius: 0;
        border-bottom: 2px solid #C4C4C4;
        color: #0E878C;
        cursor: pointer;
        display: flex;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        max-width: 100%;
        padding: 8px 16px;
        width: auto;
        
        @media(min-width: 993px) {
            border-radius: 8px;
            border-bottom: none;
            color: #666A97;
            height: 32px;
            padding: 8px;
        }
        
        &:hover {
            text-decoration: none;
        }

        &--active {
            color: #404042;
            border-bottom: 2px solid #0E878C;
            
            @media(min-width: 993px) {
                background-color: #D0F0F1;
                border-bottom: none;
            }
        }
    }
}
</style>
