export default {
    methods: {
        replaceQueryParams(filters) {
            const search_params = new URLSearchParams(window.location.search);

            for (const filter_name of Object.keys(filters)) {
                let filter_value = filters[filter_name];

                if (Array.isArray(filter_value)) {
                    filter_value = filter_value.join(',');
                }

                if (filter_value) {
                    search_params.set(filter_name, filter_value);
                } else {
                    search_params.delete(filter_name);
                }
            }

            window.history.replaceState({}, '', `?${search_params.toString()}`);
        },
        getFiltersByQueryParams(filters) {
            const formatted_filters = { ...filters };

            for (const key of Object.keys(formatted_filters)) {
                const new_value = this.$route.query[key];

                if (new_value) {
                    if (!isNaN(new_value)) {
                        formatted_filters[key] = parseFloat(new_value);
                    } else {
                        formatted_filters[key] = new_value;
                    }
                }
            }

            return formatted_filters;
        },


    },
};
