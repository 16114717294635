import actions from '@/store/modules/ui/actions';
import getters from '@/store/modules/ui/getters';
import mutations from '@/store/modules/ui/mutations';
import state from '@/store/modules/ui/state';

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
