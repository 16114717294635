<template>
    <ui-modal ref="deleteApp" centered :title="$t('Remove Authentication')" @hidden="hidden()">
        <div>
            <p>{{ $t('Are you sure you want to disable two-factor authentication?') }}</p>
            <p>{{ $t('Please note that by doing so, you will lose an additional layer of security on your account.') }}</p>
            <p class="text-muted mb-0">{{ $t('Additionally, remember that you will need to remove this record from the application you use for authentication.') }}</p>
        </div>
        <template #footer>
            <ui-button variant="secondary" :disabled="loading" @click="$refs.deleteApp.close()">
                {{ $t('Cancel') }}
            </ui-button>
            <ui-button variant="primary" :loading="loading" @click="deleteDevice">
                {{ $t('Delete') }}
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button.vue';
import UiModal from '@/components/ui/modal/Modal.vue';

export default {
    name: 'DeleteApp',
    components: { UiButton, UiModal },
    data() {
        return {
            loading: false,
            app: null,
        }
    },
    methods: {
        show(app) {
            this.app = app;
            this.$refs.deleteApp.show();
        },
        hidden() {
            this.$refs.deleteApp.close();
        },
        async deleteDevice() {
            try {
                this.loading = true;
                await this.axiosAccount.delete(`/api/two-factor/disable/${this.app.secret}`);

                this.$emit('appDeleted', this.app);
                this.$toasted.global.successMessage(this.$t('Successfully removed application'));
            } catch (err) {
                this.$toasted.global.errorMessage(this.$t('There was an error deleting the app, please try again'));
            } finally {
                this.loading = false;
                this.hidden();
            }
        }
    }
}
</script>