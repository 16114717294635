<template>
    <div class="profile-picture-selector">
        <div>
            <avatar :name="'Alan'" :lastName="'Galeana'" size="sm" />
        </div>
        <div class="profile-picture-selector__content">
            <h6 class="profile-picture-selector__title mb-0">{{ $t('Profile picture') }}</h6>
            <p class="profile-picture-selector__message mb-0">{{ $t('You don\'t have a profile picture yet.') }}</p>
        </div>
        <button class="profile-picture-selector__button d-none">
            {{ $t('Edit') }}
            <span class="fas fa-edit"></span>
        </button>
    </div>
</template>

<script>
import Avatar from '@/components/ui/Avatar.vue'

export default {
    name: 'ProfilePictureSelector',
    components: { Avatar },
};
</script>

<style lang="scss">
.profile-picture-selector {
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    display: flex;
    gap: 8px;
    padding: 16px;

    &__content {
        width: 100%;
    }

    &__title {
        font-size: 1rem;
    }

    &__message {
        color: #787F84;
        font-size: 0.875rem;
    }

    &__button {
        align-items: center;
        background-color: transparent;
        border: 1px solid #12B4BA;
        border-radius: 8px;
        color: #12B4BA;
        display: flex;
        flex-shrink: 0;
        gap: 8px;
        height: 32px;
        padding: 8px 16px;

        &:hover {
            background-color: #12B4BA;
            color: $white;
        }
    }
}
</style>