<template>
    <div class="account-finder" v-click-outside="closeDropdown" @click="openDropdown">
        <ui-input
            id="name"
            v-model="email"
            name="name"
            type="email"
            placeholder="email@example.com"
            :label="$t('Search by email')"
            :debounce="500"
            :field="v$.email"
            @input="searchEmail"
        >
            <template #prepend>
                <span class="fas fa-search"></span>
            </template>
        </ui-input>
        <div v-if="showDropdown" class="account-finder__dropdown">
            <div v-if="loading" class="account-finder__account">
                <b-skeleton type="avatar"></b-skeleton>
                <div class="flex-grow-1">
                    <b-skeleton class="w-50" />
                    <b-skeleton class="w-50" />
                </div>
            </div>
            <template v-else>
                <div v-for="account in accounts" :key="account.email" class="account-finder__account" @click.stop="selectAccount(account)">
                    <div>
                        <avatar :name="account.first_name" :lastName="account.last_name" size="xs" />
                    </div>
                    <div class="account-finder__account-content">
                        <h6 class="mb-0">
                            {{ `${account.first_name} ${account.last_name}` }}
                        </h6>
                        <p class="mb-0 text-muted">
                            {{ account.email }}
                        </p>
                    </div>
                </div>
                <div v-if="!accounts.length" class="account-finder__account" @click.stop="selectAccount(email)">
                    <div>
                        <div class="account-finder__account-icon">
                            <span class="fas fa-envelope"></span>
                        </div>
                    </div>
                    <div class="account-finder__account-content">
                        <h6 class="mb-0">
                            {{ email }}
                        </h6>
                        <p class="mb-0 text-muted">
                            {{ $t('Email not registered on the platform.') }}
                        </p>
                    </div>
                </div>
            </template>
        </div>
    </div>    
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { helpers, email } from '@vuelidate/validators';

import Avatar from '@/components/ui/Avatar.vue';
import UiInput from '@/components/ui/inputs/Input.vue';

export default {
    name: 'AccountFinder',
    components: { Avatar, UiInput },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            accounts: [],
            email: '',
            loading: false,
            showDropdown: false,
        };
    },
    validations() {
        return {
            email: {
                email: helpers.withMessage(this.$t('Email invalid'), email),
            },
        };
    },
    methods: {
        closeDropdown() {
            this.showDropdown = false;
        },
        openDropdown() {
            if (this.account || (this.email && !this.v$.$invalid)) {
                this.showDropdown = true;
            }
        },
        selectAccount(account) {
            let formatted_account = account;

            if (typeof account === 'string') {
                formatted_account = { email: account };
            }

            this.$emit('select', formatted_account);
            this.closeDropdown();
            this.email = '';
            this.v$.$reset();
        },
        async searchEmail(email) {
            this.v$.$touch();

            if(!email || this.v$.$invalid) {
                this.showDropdown = false;
                return;
            }

            try {
                this.loading = true;
                this.showDropdown = true;

                const { data } = await this.axiosAccount.get('/api/accounts/search', { params: { email } });

                this.accounts = data;
            } catch(error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        }
    },
};
</script>

<style lang="scss">
.account-finder {
    position: relative;

    &__dropdown {
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0 8px 16px rgba(#000000, 0.15);
        left: 0;
        max-height: 250px;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 99;
    }

    &__account {
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        gap: 8px;
        padding: 16px;

        &:hover {
            background-color: #f6f6f6;
        }
    }

    &__account-icon {
        align-items: center;
        background-color: $accounts-primary;
        border-radius: 100%;
        color: $white;
        display: flex;
        font-size: 1rem;
        height: 40px;
        justify-content: center;
        width: 40px;
    }

    &__account-content {
        width: 100%
    }
}
</style>
