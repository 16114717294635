import VueCookies from 'vue-cookies';

const generateDeviceIdentifier = () => {
    const device_identifier = VueCookies.get('_adid');

    if (!device_identifier) {
        const device_identifier = crypto.randomUUID();
        VueCookies.set('_adid', device_identifier, Infinity);
    }
};

generateDeviceIdentifier();
