<template>
    <div class="ui-list-group" :class="variant ? `ui-list-group--${variant}` : ''">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'ListGroup',
    props: {
        variant: {
            type: String,
            default: null,
            required: false,
        },
    },
};
</script>

<style lang="scss">
.ui-list-group {
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    &--white {
        background-color: $white;
    }
}
</style>
