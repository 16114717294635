export const EcUserModel = function () {
    return {
        id: null,
        active: false,
        confirmated: false,
        complete: false,
        email: null,
        country: null,
        settings: {},
        seller_id: null,
        first_name: null,
        last_name: null,
        phone: null,
        store_id: null,
    }
};

export const EcStoreModel = function () {
    return {
        active: false,
        name: ' ',
        phone: null,
        email: null,
        description: ' ',
        image: null,
        image_url: null,
        settings: {
            commission: null,
            language: null,
        },
        currency: null,
        storeId: null,
        id: null,
        ecommerce: null,
        url: null,
        stadistics: {
            products: {},
            orders: {},
        }
    }
};
export const EcStoreAddressModel = function () {
    return {
        address_name: null,
        first_name: null,
        last_name: null,
        address1: null,
        address2: null,
        country: {
            code: null,
        },
        state: {
            code: null,
            name: null,
        },
        city: null,
        postal_code: null,
        phone: null,
    }
};
export const EcProductModel = function () {
    return {
        name: null,
        description: null,
        preparation_time: null,
        sales: null,
        views: null,
        language: null,
        sku: null,
        quantity: null,
        tags: [],
        dimensions: {
            width: null,
            height: null,
            length: null,
            weight: null,
        },
        discounts: [],
        attributes: [],
        variants: [],
        category: {
            id: null,
        },
        units: {
            height: "cm",
            length: "cm",
            weight: "kg",
            width: "cm"
        },
        active: false,
        condition: true,
        sell_out_stock: false,
        currency: '',
        images: [],
        tempImages: [],
        imageUrl: null,
    }
};

export const EcVariantModel = function () {
    return {
        sku: null,
        name: null,
        quantity: null,
        price: null,
        option1: null,
        option2: null,
        option3: null,
        dimensions: {
            width: null,
            height: null,
            length: null,
            weight: null,
        },
        units: {
            width: 'cm',
            height: 'cm',
            length: 'cm',
            weight: 'kg',
        },
    }
};