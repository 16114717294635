const deleteValue = ({ commit }, payload) => {
    commit('DELETE_VALUE', payload);
};

const setCountry = ({ commit }, payload) => {
    commit('SET_COUNTRY', payload);
};

const updateContextValue = ({ commit }, payload) => {
    commit('UPDATE_CONTEXT_VALUE', payload);
};

const updateValue = ({ commit }, payload) => {
    commit('UPDATE_VALUE', payload);
};

const updateValues = ({ commit }, payload) => {
    commit('UPDATE_VALUES', payload);
};

export default {
    deleteValue,
    setCountry,
    updateContextValue,
    updateValue,
    updateValues,
};
