const methods = {
    fnGlobalGetStoreName(storesList, storeId) {
        let tempStore = storesList.find(store => store.id == storeId);
        return (tempStore ? tempStore.name : '');
    },
    fnCutText(text, size) {
        if (text.length > size) {
            text = text.slice(0, size - 1) + '...';
            return text

        } else {
            return text
        }
    },
    async fnApiGetCountries() {
        return await this.axiosAccount.get('/api/accounts/countries')
            .then(response => {
                return this.fnSortCountries(response.data);
            })
            .catch(() => []);
    },

    async fnApiGetStates(code) {
        let tempStates = localStorage.getItem('states');
        if (tempStates) {
            tempStates = JSON.parse(tempStates);
        }
        if (tempStates?.[code]) {
            return tempStates[code];
        } else {

            return await axiosAccount.get('../states?country=' + code).then(response => {
                if (tempStates == null) {
                    tempStates = {};
                    tempStates[code] = response.data;
                } else {
                    tempStates[code] = response.data;
                }
                localStorage.setItem('states', JSON.stringify(tempStates));
                return response.data;
            }).catch(error => {});
        }

    },
    async fnApiGetProvinces(code) {
        return await axios.get(`../provinces/${code}`).then(response => {
            return response.data;
        }).catch(error => {});
    },

    fnToastMessage(message, variant, delay) {
        this.$toasted.show(message, {
            icon: {
                name: 'info-circle',
                after: true,
            },
            theme: 'outline',
            position: "top-right",
            fullWidth: false,
            type: variant || 'info',
            duration: delay || 2000
        });
    },

    fnModalMessage(title, message, variant, delay) {
        this.$bvModal.msgBoxOk(message, {
            title: title || 'Info',
            size: 'md',
            buttonSize: 'md',
            okVariant: variant || 'info',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
        });
    },

    fnSortCountries(countries) {
        return this.$lodash.sortBy(countries, ['name']);
    },

    fnDeleteCountry(countries, code) {
        let index = this.$lodash.findIndex(countries, ['code', code]);
        if (index >= 0) {
            countries.splice(index, 1);
        }
        return countries;
    },

    // DATES
    fnDate(date, format) {
        date = this.$moment(date).format(format);
        let tmepDate = this.$moment().format(format);

        if (date == tmepDate) {
            return this.$t('tables.today');
        } else {
            return date;
        }
    },

    fnDateNotBeforeToday(date) {
        return date < this.$moment().subtract(1, 'days');
    },

    // ecommerces
    fnGetEcommerceLogo(logoList, name) {
        if (name != 'Ecart') {
            let ecommerce = this.$lodash.find(logoList, {
                name
            });
            return (ecommerce) ? (ecommerce.image || null) : null;
        } else {
            return require('@/assets/images/ecart-ecommerce.png')
        }

    },

    async fnApiGetEcommercesTemp() {
        this.CONST.ECOMMERCES = localStorage.getItem('ecommerces');
        if (this.CONST.ECOMMERCES == undefined) {
            await axios.get('../ecommerces').then((response) => {
                this.CONST.ECOMMERCES = this.$lodash.sortBy(response.data, ['name'])
                localStorage.setItem('ecommerces', JSON.stringify(this.CONST.ECOMMERCES));
            }).catch();
            return true;
        } else {
            this.CONST.ECOMMERCES = JSON.parse(this.CONST.ECOMMERCES);
            return true;
        }
    },


    fnFormatPrice(price, currency, discount) {
        if (price != undefined && price != undefined) {
            if (discount) {
                price = price - ((price / 100) * discount)
            }
            let separador = ',';
            let sepDecimal = '.';
            price = '' + price.toFixed(2);
            let splitStr = price.split('.');
            let splitLeft = splitStr[0];
            let splitRight = splitStr.length > 1 ? sepDecimal + splitStr[1] : '';
            let regx = /(\d+)(\d{3})/;
            while (regx.test(splitLeft)) {
                splitLeft = splitLeft.replace(regx, '$1' + separador + '$2');
            }
            if (currency == null) {
                currency = "";
            }
            return '$' + splitLeft + splitRight + currency;
        }
    },
    fnFormatAddress(address) {
        let tempAddress = address.address2 + ', ' + address.address1 + ', ' + address.city + ', ' + address.state.code + ', ';
        tempAddress += (address.country.name ? address.country.name : address.country.code) + '. ' + address.postal_code;

        return tempAddress;
    },
    fnFormatDimenisons(dimensions) {
        if (dimensions.width) {
            return (dimensions.width + ' X ' + dimensions.height + ' X ' + dimensions.length + ' - ' + `<strong class="f-w-500">${dimensions.weight}KG</strong>`);
        } else {
            return this.$t('tables.none');
        }
    },
    fnStoreURL(url) {
        if (url != undefined) {
            let tempUrl = url.split('//');
            return tempUrl[1]
        } else {
            return '';
        }
    },

    // PROIDUCT CAEGORY
    fnNormalizer(node) {
        return {
            id: node.id,
            label: node.name,
            children: node.subcategories,
        }
    },
    fnApiGetCategories({
        action,
        searchQuery,
        callback
    }) {
        if (searchQuery.length > 0) {
            let language = ''
            switch (this.$store.state.language) {
                case 'en':
                    language = 'en-US';
                    break;
                case 'es':
                    language = 'es-ES';
                    break;
            }
            axios.get(`categories?lang=${language}&q=${encodeURIComponent(searchQuery) }`).then(async (response) => {
                callback(null, response.data)
            }).catch(error => {});
        } else {
            callback(null, [])
        }
    },

    fnGetStatusClass(status) {
        let tempClass = 'text-info';
        for (let key of Object.keys(this.CONST.STATUS)) {
            if (status == key) {
                tempClass = this.CONST.STATUS[status].class;
            }
        }
        return tempClass;
    },
    fnST(path, key) {
        if (this.$te(path + key)) {
            return this.$t(path + key);
        } else {
            return key;
        }
    },
    fnLink(link) {
        window.open(link);
    },
    fnGetFlagByCurrency(currency) {
        let tempFlag = this.CONST.CURRENCIES_LIST[currency];
        if (tempFlag) {
            return tempFlag.flag;
        } else {
            return null
        }
    },
    fnGlobalGetAddress(newAddress) {
        let tempAddress = {
            address_name: newAddress.address_name,
            first_name: newAddress.first_name,
            last_name: newAddress.last_name,
            address1: newAddress.address1,
            address2: newAddress.address2,
            country: {
                code: newAddress.country.code
            },
            city: newAddress.city,
            postal_code: newAddress.postal_code,
            phone: newAddress.phone,
            state: {},
        };
        if (newAddress.state.code) {
            tempAddress.state.code = newAddress.state.code;
        } else {
            tempAddress.state.name = newAddress.state.name;
        }
        return tempAddress;
    },

    fnAccountNumber(number) {
        return '********' + number.slice(-4);
    },
    fnGlobalScrollToError(refsList, animated) {
        for (let key of Object.keys(refsList)) {
            if (refsList[key].errors && (refsList[key].errors.length > 0)) {
                refsList[key].$el.scrollIntoView({
                    block: 'center',
                    behavior: "smooth"
                });
                if (animated) {
                    this.fnGlobalFocusAnimation(refsList[key].$el.children[0]);
                }
                break;
            }
        }
    },
    fnGlobalScrollTo(containerId, block) {
        let tempElement = document.getElementById(containerId);
        if (tempElement) {
            tempElement.scrollIntoView({
                block: block || 'center',
                behavior: "smooth"
            });
            this.fnGlobalFocusAnimation(tempElement);
        }
    },
    fnGlobalFocusAnimation(elementContainer) {
        setTimeout(() => {
            elementContainer.classList.add("animated");
            elementContainer.classList.add("shake");
        }, 300)
        setTimeout(() => {
            elementContainer.classList.remove("animated");
            elementContainer.classList.remove("shake");
        }, 1000)
    },
    fnGetStars(qualification) {
        let fillStar = '<i class="fa fa-star"></i>';
        let emptyStar = '<i class="far fa-star"></i>';
        let stars = '';
        if ((qualification > 0) && (qualification <= 5)) {
            for (let index = 1; index <= 5; index++) {
                (index <= qualification) ? (stars += fillStar) : (stars += emptyStar);
            }
            return stars;
        } else {
            return '';
        }
    },

    fnGlobalGetTime(date) {
        let today = this.$moment();
        date = this.$moment(date);
        // Same year
        if (today.get('year') == date.get('year')) {
            // Same month
            if (today.get('month') == date.get('month')) {
                // Same dayOfYear
                if (today.get('dayOfYear') == date.get('dayOfYear')) {
                    // Same hour 
                    if ((today.get('hour') - date.get('hour')) > 1) {
                        return this.$tc('dates.justNow', 2, {
                            hours: (today.get('hour') - date.get('hour'))
                        });
                    } else {
                        return this.$tc('dates.justNow', 1);
                    };
                } else {
                    if ((today.get('dayOfYear') - date.get('dayOfYear')) > 1) {
                        return this.$tc('dates.daysAgo', 2, {
                            days: (today.get('dayOfYear') - date.get('dayOfYears'))
                        });
                    } else {
                        return this.$tc('dates.daysAgo', 1);
                    }
                };
            } else {
                if ((today.get('month') - date.get('month')) > 1) {
                    return this.$tc('dates.monthsAgo', 2, {
                        months: (today.get('month') - date.get('month'))
                    });
                } else {
                    return this.$tc('dates.monthsAgo', 1);
                }
            };
        } else {
            return date.format(this.CONST.DATE_FORMATS.DD_MM_YYYY);
        };
    },

    fnGlobalStoreIntegrationLink(newTab, device, ecommerce) {
        let tempLink = 'https://ecartapi.com/oauth/' + this.CONST.ECART.LINK + '?nobar=true&from=ecart';
        if (ecommerce) {
            tempLink += `&ecommerce=${ecommerce}`;
        }
        if (device && device == 'ios') {
            tempLink += `&back_device=true`;
        }
        if (newTab && (device == null)) {
            window.open(tempLink);
        } else {
            window.location.assign(tempLink);
        }
    },

    fnGlobalScroll(containerId, position) {
        if (containerId) {
            setTimeout(() => {
                document.getElementById(containerId).scrollIntoView({
                    block: position,
                    behavior: "smooth"
                });
            }, 300);
        }
    },


    // ANIMATIONS
    beforeEnter: function (el) {
        el.style.opacity = 0
        el.style.height = 0
    },
    enter: function (el, done) {
        let delay = el.dataset.index * 150
        setTimeout(function () {
            Velocity(
                el, {
                    opacity: 1,
                    height: '1.6em'
                }, {
                    complete: done
                }
            )
        }, delay)
    },
    leave: function (el, done) {
        let delay = el.dataset.index * 150
        setTimeout(function () {
            Velocity(
                el, {
                    opacity: 0,
                    height: 0
                }, {
                    complete: done
                }
            )
        }, delay)
    },

    fnAccounts(type) {
        window.location.assign(type ? this.CONST.ACCOUNTSURL.replace("login", type) : this.CONST.ACCOUNTSURL);
    },

    showError(error) {
        let message = error?.response?.data?.message;
        message = message ? this.$t(message) : this.$t('Something went wrong, try later');

        if (process.env.NODE_ENV === 'development') {
            console.log(error);
        }

        this.$toasted.global.errorMessage(message);
    },

    cryptoRandom() {
        const array = new Uint8Array(1);
        const random_value = crypto.getRandomValues(array)[0];

        return random_value / Math.pow(2, 8);
    },

    makeID(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i += 1) {
            result += characters.charAt(Math.floor(this.cryptoRandom() * charactersLength));
        }
        return result;
    },
}

export default methods;