<template>
    <div class="ui-new-input" @click="$emit('click', $event)">
        <label v-if="label" :for="id" class="ui-new-input__label">
            {{ label }}
            <template v-if="isOptional">({{ $t('Optional') }})</template>
        </label>
        <div class="ui-new-input__container">
            <div v-if="$slots.prepend" class="ui-new-input__prepend">
                <slot name="prepend"></slot>
            </div>
            <input
                :id="id"
                ref="input"
                :name="name"
                :type="type"
                :placeholder="placeholder"
                :value="value"
                :disabled="disabled"
                :min="min"
                :max="max"
                :autocomplete="autocomplete"
                :maxlength="maxlength"
                :class="{
                    'ui-new-input__input--valid': field && field.$dirty && !field.$error,
                    'ui-new-input__input--invalid': field && field.$dirty && field.$error,
                    'ui-new-input__input--has-prepend': !!$slots.prepend,
                    'ui-new-input__input--has-append': !!$slots.append,
                }"
                class="ui-new-input__input"
                novalidate="true"
                @input="handleInput"
                @change="handleChange"
            />
            <div v-if="$slots.append" class="ui-new-input__append">
                <slot name="append"></slot>
            </div>
        </div>
        <ui-invalid-feedback :field="field" />
    </div>
</template>

<script>
import UiInvalidFeedback from '@/components/ui/InvalidFeedback.vue';

export default {
    name: 'UiInput',
    components: { UiInvalidFeedback },
    props: {
        autocomplete: {
            type: String,
            default: null,
            required: false
        },
        debounce: {
            type: Number,
            default: 0,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        field: {
            type: Object,
            default: null,
            required: false,
        },
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        min: {
            type: String,
            required: false,
            default: null,
        },
        max: {
            type: String,
            required: false,
            default: null,
        },
        maxlength: {
            type: Number,
            required: false,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: null,
            required: false,
        },
        type: {
            type: String,
            default: 'text',
            required: true,
        },
        value: {
            type: String,
            default: '',
            required: true,
        },
    },
    computed: {
        isOptional() {
            return this.field && !this.field?.hasOwnProperty('required');
        },
    },
    methods: {
        handleInput(event) {
            const value = event.target.value;

            clearTimeout(this.timer);

            if (this.debounce === 0) {
                this.$emit('input', value);
            } else {
                this.timer = setTimeout(() => {
                    this.$emit('input', value);
                }, this.debounce);
            }
        },
        handleChange(event) {
            const value = event.target.value;
            this.$emit('change', value);
        },
        focus() {
            this.$refs.input.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-new-input {
    &__label {
        color: $general-black;
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 4px;
    }

    &__container {
        display: flex;
        align-items: center;
    }

    &__input {
        background-color: $white;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        color: $general-black;
        font-size: 0.875rem;
        font-weight: 400;
        height: 40px;
        line-height: 1;
        padding: 8px;
        width: 100%;

        &::placeholder {
            color: $ecart-pay-secondary-300;
        }

        &:disabled {
            background-color: $accounts-secondary-100;
            color: $ecart-pay-secondary-300;
        }

        &:focus {
            border-color: #AEE8E9;
            outline: 1px solid #AEE8E9;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active  {
            -webkit-background-clip: text;
        }

        &--valid {
            border-color: $general-success;

            &:focus {
                border-color: $general-success;
                outline: 1px solid $general-success
            }
        }

        &--invalid {
            border-color: $general-error;

            &:focus {
                border-color: $general-error;
                outline: 1px solid $general-error;
            }
        }

        &--has-prepend {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &--has-append {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__prepend {
        align-items: center;
        background-color: #f1f1f1;
        border: 1px solid #d9d9d9;
        border-right: none;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        display: flex;
        font-size: 0.875rem;
        color: $general-black;
        height: 40px;
        padding: 8px;
    }

    &__append {
        align-items: center;
        background-color: #f1f1f1;
        border: 1px solid #d9d9d9;
        border-left: none;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        font-size: 0.875rem;
        color: $general-black;
        height: 40px;
        padding: 8px;
    }
}
</style>
