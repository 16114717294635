import ScopesEdit from '@/views/profile/scopes/edit.vue';
import ScopesIndex from '@/views/profile/scopes/index.vue';
import ScopesRegister from '@/views/profile/scopes/register.vue';
import CompanyScopes from '@/views/profile/companies/_id/scopes/index.vue';
import { useView, useLayout } from '@/functions';

export default {
    path: '/profile',
    component: useLayout('dashboard'),
    children: [
        {
            path: '',
            name: 'profile',
            component: useView('profile/profile_page'),
            meta: {
                title: 'Your profile',
            },
        },
        {
            path: 'companies',
            name: 'companies',
            component: useView('profile/companies_page'),
            meta: {
                title: 'Your organizations',
            },
        },
        {
            path: 'companies/:id/scopes',
            name: 'scopes-company',
            component: CompanyScopes,
            meta: {
                title: 'Organization users',
            },
        },
        {
            path: 'scopes',
            name: 'scopes',
            component: ScopesIndex,
            meta: {
                title: 'Scopes',
            },
        },
        {
            path: 'scopes/register',
            name: 'scopes-register',
            component: ScopesRegister,
            meta: {
                title: 'Add user to the organization',
            },
        },
        {
            path: 'scopes/:id/edit',
            name: 'scopes-edit',
            component: ScopesEdit,
            meta: {
                title: 'Edit user scopes',
            },
        },
    ],
};