const state = {
    context: {
        country: 'MX',
        locales: [],
        states: [],
        suburbs: []
    },
    values: {},
};

export default state;
