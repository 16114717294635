<template>
    <div class="permission-selector">
        <p class="permission-selector__section-subtitle">
            <span class="fas fa-shield-alt"></span>
            {{ $t('Update the permission list related to this scope') }}
        </p>
        <ui-input
            id="filter"
            name="filter"
            placeholder="Filter permissions" 
            v-model="filterText"
            class="permission-selector__filter-input"
            type="text"
            />
        <div class="permission-selector__list">
            <div 
            v-for="scope in filteredPermissions" 
            class="permission-selector__list-item" 
            :key="scope.name"
            >
            <h6 class="permission-selector__scope-name">{{ scope.name }}</h6>
            <ui-list class="permission-selector__permission-list">
                    <ui-list-item
                    v-for="permission in scope.permissions"
                    :key="permission.id"
                    class="permission-selector__permission-list-item"
                    >
                    <div class="permission-selector__permission-content">
                        <div>
                        <h6 class="permission-selector__permission-name">
                            {{ $t(permission.name) }}
                        </h6>
                        <p class="permission-selector__permission-description">
                            {{ $t(permission.description) }}
                        </p>
                        </div>
                        <div>
                        <ui-switch
                            id="active"
                            name="active"
                            v-model="permission.enabled"
                            @change="togglePermission(permission)"
                        />
                        </div>
                    </div>
                    </ui-list-item>
                </ui-list>
            </div>
            <empty-state 
            v-if="!filteredPermissions.length" 
            img="empty-box.svg" 
            :text="$t('This site does not have permissions available to manage.')" 
            />
      </div>
    </div>
  </template>
  
  <script>
import EmptyState from '@/components/ui/EmptyState.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import UiSwitch from '@/components/ui/Switch.vue';
import UiInput from '@/components/ui/inputs/Input.vue';

import scopesMixin from '@/plugins/mixin/scopes.mixin';

export default {
    name: 'ScopePermissionSelector',
    components: {
        EmptyState,
        UiList,
        UiListItem,
        UiInput,
        UiSwitch,
    },
    props: {
        scope: {
            type: Object,
            default: null,
            required: true,
        },
        editPermisson: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    mixins: [scopesMixin],
    data() {
        return {
            permissions: [],
            filterText: '',
        };
    },
    watch: {
        'scope.role': {
            handler(newVal) {
                const { scopes } = this.scope.site;

                if (scopes?.length) {
                    this.permissions = this.groupPermissions(scopes);
                }

                if (newVal === 'USER') {
                    const active_Scopes = this.scope.scopes;
                    const filterPermission = [];

                    if (active_Scopes.includes('write_scopes')) {
                        filterPermission.push({
                            enabled: true,
                            key: 'write_scopes'
                        });
                    }

                    if (active_Scopes.includes('read_scopes')) {
                        filterPermission.push({
                            enabled: true,
                            key: 'read_scopes'
                        });
                    }

                    filterPermission.forEach(permission => {
                        this.togglePermission(permission);
                    });
                }
            },
            immediate: true,
        },
    },
    async mounted() {
        await this.createToken();

        const { scopes } = this.scope.site;

        if (scopes?.length) {
            this.permissions = this.groupPermissions(scopes);
        }
    },
    computed: {
      filteredPermissions() {
        const filter = this.$t(`${this.filterText.toLowerCase()}`);
        return this.permissions
          .map(scope => {
            const filteredPermissions = scope.permissions.filter(permission =>
              permission.name.toLowerCase().includes(filter)
            );
            return {
              ...scope,
              permissions: filteredPermissions,
            };
          })
          .filter(scope => scope.permissions.length > 0);
      },
    },
    methods: {
        groupPermissions(permissions) {
            if (this.scope.role === 'USER') {
                permissions = permissions.filter(permission => permission.group !== 'scopes');
            }
            const grouped_permisions = permissions.reduce((accumulator, current_value) => {
                const group = accumulator.find(item => item.key === current_value.group);
                const permission_name = current_value.name.charAt(0).toUpperCase() + current_value.name.slice(1)
                
                if (!group) {
                    const group_name = current_value.group.charAt(0).toUpperCase() + current_value.group.slice(1);

                    accumulator.push({
                        key: current_value.group,
                        name: group_name.replace(/_/g, ' '),
                        permissions: [{
                            key: current_value.name,
                            name: permission_name.replace(/_/g, ' '),
                            description: current_value.description,
                            enabled: this.scope.scopes.includes(current_value.name)
                        }],
                    });
                } else {
                    group.permissions.push({
                        key: current_value.name,
                        name: permission_name.replace(/_/g, ' '),
                        description: current_value.description,
                        enabled: this.scope.scopes.includes(current_value.name)
                    });
                }

                return accumulator;
            }, []);

            return grouped_permisions;
        },
        async togglePermission(permission) {
            try {
                permission.enabled = !permission.enabled;

                const config = {};

                if (this.scopeToken) {
                    config.headers = { Authorization: this.scopeToken };
                }

                const form_data = {
                    id: this.scope.id,
                    attribute: 'scopes',
                    active: permission.enabled,
                    value: permission.key,
                };
                await this.axiosAccount.patch('/api/scopes/toggle', form_data, config);

                this.$emit('updatedPermission', {
                    scope: this.scope,
                    permission: { ...permission },
                });
            } catch(error) {
                permission.enabled = !permission.enabled;
                this.showError(error);
            }
        },
    },
};
</script>

<style lang="scss">
.permission-selector {
    &__section-subtitle {
        color: $accounts-secondary-400;
    }

    &__scope-name {
        color: $general-black;
        font-size: 0.875rem;
        font-weight: 600;
    }

    &__permission-content {
        align-items: flex-start;
        display: flex;
        gap: 16px;
        justify-content: space-between;
        width: 100%;
    }

    &__list-item {
        margin-bottom: 16px;
        background-color: #f3f3f3;
        padding: 16px;
        border-radius: 8px;
    }

    &__permission-list {
        padding-left: 8px;
        padding-right: 8px;
    }

    &__permission-name {
        color: $general-black;
        font-size: 0.875rem;
    }

    &__permission-description {
        color: rgba($general-black, 0.8);
        margin-bottom: 0;
    }
  
    &__filter-input {
      margin-bottom: 16px;
      padding: 8px;
      border-radius: 4px;
      width: 100%;
    }
}
</style>
  