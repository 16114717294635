import Vue from 'vue';
import { sanitizeUrl } from '@braintree/sanitize-url';

const noImage = require('../../src/assets/images/no-image.jpg');

Vue.directive('image', {

  inserted: (element, binding) => {
    
    function loadImage() {
      if (binding.value) {
        if (element.src == null) {
          element.src = noImage;
        }
        var img = new Image();
        img.onload = () => element.src = binding.value;
        img.onerror = () => {
          element.src = noImage
        };
        img.src = binding.value;
      } else {
        element.src = noImage;
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(element);
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: "0"
      };
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(element);
    }
    if (window["IntersectionObserver"]) {
      createObserver();
    } else {
      loadImage();
    }
  }

})

Vue.directive('sanitize-href', {
    inserted(el, binding) {
        if (binding.value) {
            el.href = sanitizeUrl(binding.value);
        }
    },
    update(el, binding) {
        if (binding.value) {
            el.href = sanitizeUrl(binding.value);
        }
    }
});
