var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-phone-input"},[_c('label',{staticClass:"ui-phone-input__label",attrs:{"for":_vm.id}},[_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),(_vm.isOptional)?[_vm._v("("+_vm._s(_vm.$t('Optional'))+")")]:_vm._e()],2),_c('vue-tel-input',{ref:"vueTelInput",attrs:{"valid-characters-only":"","default-country":_vm.defaultCountry,"auto-default-country":_vm.autoDefaultCountry,"disabled":_vm.disabled,"dropdown-options":{
            disabled: _vm.disableDropdown,
            showDialCodeInSelection: true,
            showFlags: true,
            showSearchBox: true,
        },"input-options":{
            autocomplete: _vm.autocomplete,
            id: _vm.id,
            name: _vm.name,
            placeholder: _vm.placeholder,
            styleClasses: 'ui-phone-input__input',
        },"style-classes":[
            'ui-phone-input__container',
            {
                'ui-phone-input__container--valid': _vm.field && _vm.field.$dirty && !_vm.field.$error,
                'ui-phone-input__container--invalid': _vm.field && _vm.field.$dirty && _vm.field.$error,
            }
        ],"value":_vm.value},on:{"input":_vm.onInput},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('ui-invalid-feedback',{attrs:{"field":_vm.field}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }