export default {
    hideChat() {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (!mutation.addedNodes) {
                    return;
                }

                for (const node of mutation.addedNodes) {
                    if (node.id === 'chat-widget-container') {
                        node.style.display = 'none';
                        observer.disconnect();
                    }
                }
            });
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
            attributes: false,
            characterData: false,
        });
    },
};
