<template>
    <div class="profile-index">
        <p class="profile-index__text">
            {{ $t('Manage your personal information.') }}
        </p>
        <div class="row">
            <div class="col-md-12">
                <dashboard-profile-card>
                    <profile-picture-selector class="d-none" />
                    <personal-information-card />
                </dashboard-profile-card>
            </div>
        </div>
    </div>
</template>

<script>
import DashboardProfileCard from '@/components/profile/DashboardProfileCard.vue';
import PersonalInformationCard from '@/components/profile/PersonalInformationCard.vue';
import ProfilePictureSelector from '@/components/profile/ProfilePictureSelector.vue';

export default {
    name: 'ProfileIndex',
    components: {
        DashboardProfileCard,
        PersonalInformationCard,
        ProfilePictureSelector,
    },
};
</script>
