<template>
    <div class="scope-form-skeleton">
        <b-skeleton class="scope-form-skeleton__title mb-3" />
        <div class="mb-3">
            <b-skeleton class="scope-form-skeleton__input mb-2" type="input" />
        </div>
        <b-skeleton class="scope-form-skeleton__title mb-3" />
        <div class="row">
            <div class="col-md-4 mb-3">
                <b-skeleton class="scope-form-skeleton__input mb-2" type="input" />
            </div>
            <div class="col-md-4 mb-3">
                <b-skeleton class="scope-form-skeleton__input mb-2" type="input" />
            </div>
            <div class="col-md-4 mb-3">
                <b-skeleton class="scope-form-skeleton__input mb-2" type="input" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScopeFormSkeleton',
};
</script>

<style lang="scss">
    .scope-form-skeleton {
        &__title {
            height: 21px !important;
            max-width: 20%;
        }

        &__input {
            width: 100%;
            height: 38px;
        }
    }
</style>
