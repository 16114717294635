import { getRoute, useLayout } from '@/functions';

export default {
    path: '/',
    component:  useLayout('secondary'),
    children: [
        getRoute(
            'verification/form',
            'verification',
            'verification/verification_page',
            { title: 'Verification' }
        ),
        getRoute(
            'verification/status/:status',
            'verificationStatusPage',
            'verification/status_page',
            { title: 'Verification status' }
        ),
    ],
};
