<template>
  <div id="app" class="h-100">
    <router-view class="h-100"></router-view>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import supportChatMixin from '@/plugins/mixin/support-chat.mixin';

export default {
  name: 'app',
  mixins: [supportChatMixin],
  data() {
    return {
      show: true
    }
  },
  computed: {
    ...mapGetters('ui', ['language']),
  },
  watch: {
    language() {
      this.$i18n.locale = this.language;
    },
    $route() {
      this.handleResize();
    }
  },
  mounted() {
    this.initLanguage();
    this.getDeviceAccounts();
    this.getSites();
    this.hideSupportChatWhenEmbed();
  },
  methods: {
    ...mapMutations(['handleResize', 'setIntegration','setLanguage']),
    ...mapMutations('EcartNotifications', ['resetNotifications']),
    ...mapActions('EcartNotifications', ['fnApiGetPendingNotificationsCount', 'fnApiGetPendingNotifications']),
    ...mapMutations('session', ['readLocalSession']),
    ...mapActions('session', ['fnGetUserInfo']),
    ...mapActions('ui', ['getLanguages', 'setInitialLanguage', 'setLanguage', 'getSites']),
    ...mapActions('deviceAccounts', ['setAccounts']),
    initLanguage() {
      try {
        this.getLanguages();
        const query_params = new URLSearchParams(window.location.search);
        const lang_param = query_params.get('lang');
        const _v = query_params.get('_v')

        if (_v) {
          const decodedServiceData = Buffer.from(_v, 'base64');
          const parsedServiceData = JSON.parse(decodedServiceData.toString())
          const { lang } = parsedServiceData;

          if (lang) {
            this.setLanguage(lang);
          }
        }

        if (lang_param) {
          this.setLanguage(lang_param);
        }

        if (!_v && !lang_param) {
          this.setInitialLanguage();
        }
      } catch(error) {
        this.setLanguage('en-US');
      }
    },
    getDeviceAccounts() {
      let device_accounts = this.$cookies.get('_acli');
      
      if (device_accounts) {
        device_accounts = JSON.parse(Buffer.from(device_accounts, 'base64'));

        if (device_accounts) {
          this.setAccounts(device_accounts);
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&family=Inter:wght@300;400;500;600;700;800&display=swap');

#app {
  font-family: Montserrat, sans-serif;
}
</style>