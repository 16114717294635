<template>
    <div>
        <list-group-skeleton v-if="loading" :rows="3" />
        <list v-else>
            <list-item v-for="auth in auths" :key="auth.id">
                <device-item ref="deviceItem" :auth="auth" @deleteApp="openDeleteDeviceModal" />
            </list-item>
            <list-item v-if="auths.length === 0">
                <p class="two-factor-authentication-card__empty-message text-muted">
                    <span class="fas fa-info-circle"></span>
                    {{ $t('You do not yet have devices linked for this authentication method.') }}
                </p>
            </list-item>
        </list>
        <add-device-modal ref="AddDeviceModal" @addApp="appAdded" />
        <delete-device-modal ref="DeleteDeviceModal" @appDeleted="appDeleted"/>
    </div>
</template>


<script>
import DeviceItem from '@/components/authentication/two-factor/DeviceItem.vue';
import List from '@/components/ui/list/List';
import ListItem from '@/components/ui/list/ListItem';
import ListGroupSkeleton from '@/components/ui/skeletons/ListGroupSkeleton.vue';
import AddDeviceModal from '@/components/authentication/two-factor/AddDeviceModal.vue';
import DeleteDeviceModal from '@/components/authentication/two-factor/DeleteDeviceModal.vue';

export default {
    name: 'DeviceList',
    components: {
        DeviceItem,
        List,
        ListItem,
        AddDeviceModal,
        DeleteDeviceModal,
        ListGroupSkeleton
    },
    data() {
        return {
            loading: false,
            auths: [],
        };
    },
    mounted() {
        this.getAllAuths();
    },
    methods: {
        openAddDeviceModal() {
            this.$refs.AddDeviceModal.open();
        },
        openDeleteDeviceModal(app) {
            this.$refs.DeleteDeviceModal.show(app);
        },
        appAdded(app) {
            this.auths.push(app);
        },
        appDeleted(app) {
            const search_index = this.auths.findIndex(item => item.secret === app.secret);
            this.auths.splice(search_index, 1);
        },
        async getAllAuths() {
            try {
                this.loading = true;
                const { data } = await this.axiosAccount.get('/api/two-factor/all');
                this.auths = data.doc;
            } catch(error) {
                this.$toasted.global.errorMessage(error);
            } finally {
                this.loading = false;
            }
        }
    },
};
</script>
