import { useView } from '@/functions';

export default {
    path: '/',
    component: useView('accounts/index_page'),
    children: [
        {
            path: 'kyb',
            name: 'kyb',
            component: useView('accounts/kyb/kyb'),
            meta: {
                title: 'KYB Documents'
            }
        },
    ],
};