<template>
    <ui-modal
        ref="addDevice"
        :title="$t('Two factor authentication')"
        centered
        :loading="loading"
    >
        <register-auth-form ref="registerAuthForm" />
        <template #footer>
            <ui-button variant="secondary" :disabled="loading" @click="close">
                {{ $t('Cancel') }}
            </ui-button>
            <ui-button variant="primary" :loading="loading" @click="addDevice">
                {{ $t('Confirm') }}
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import UiModal from '@/components/ui/modal/Modal.vue';
import RegisterAuthForm from '@/components/twofactorauth/RegisterAuthForm.vue';
import UiButton from '@/components/ui/buttons/Button.vue';

export default {
    name: 'AddDeviceModal',
    components: { UiModal, RegisterAuthForm, UiButton },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        open() {
            this.$refs.addDevice.show();
        },
        close() {
            this.$refs.addDevice.close();
        },
        async addDevice() {
            try {
                this.loading = true;

                if (!this.$refs.registerAuthForm.validate()) {
                    return;
                }

                const { data } = await this.axiosAccount.post('/api/two-factor/verify', this.$refs.registerAuthForm.formData);

                this.$emit('addApp', data.doc );
                this.$toasted.global.successMessage(this.$t('Two-factor authentication has been successfully activated'));
                this.close();
            } catch(error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>