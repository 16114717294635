function refresh(promise) {
    return promise.catch(() => {
        window.location.reload();
    })
}

const useView = component => () => refresh(import(`@/pages/${component}.vue`));
const useLayout = layout => () => refresh(import(`@/layouts/${layout}.vue`));

const getRoute = (path, name, component, meta = {}) => ({
    path,
    name,
    component: useView(component),
    meta,
});

export {
    useView,
    useLayout,
    getRoute,
}