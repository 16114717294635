import { getRoute, useLayout } from '@/functions';

export default {
    path: '/',
    component: useLayout('secondary'),
    children: [
        getRoute('/phone-verification', 'phoneVerification', 'phone-verification/phone_verification_page', { title: 'Accounts Phone Verification' }),
        getRoute('/new-user', 'accountNewUser', 'new-user/new_user_page', { title: 'Accounts New User' }),
        getRoute('/recovery-password', 'recoveryPassword', 'recovery-password/recovery_password_page', { title: 'Accounts Recovery Password' }),
        getRoute('/two-factor-authentication', 'twoFactorAuthentication', 'twofactorauth/two_factor_authentication_page', { title: 'Enable Two Factor Authentication'}),
        getRoute('/report-device', 'reportDevice', 'report-device/report_device_page', { title: 'Repor New Login Detected' }),
        getRoute('/close-session', 'closeSession', 'report_device/close_session_page', { title: 'Close Session' }),
    ],
};