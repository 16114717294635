<template>
    <div class="application-form">
        <h6 class="application-form__title">
            {{ $t('Set up Google Authenticator or another authentication app.') }}
        </h6>
        <ul class="application-form__subtitle">
            <li>{{ $t('Download and install Google Authenticator (or a similar app) on your phone.') }}</li>
            <li>{{ $t('Open the app and scan the QR code below.') }}</li>
            <li>{{ $t('Enter the verification code generated by the app.') }}</li>
        </ul>
        <hr>
        <div class="text-center">
            <h6 class="application-form__title">
                {{ $t('Scan the QR code with your phone.') }}
            </h6>
            <img :src="qrCode.url" alt="QR Code">
            <h6 class="application-form__title">
                {{ $t('Or manually enter the code into your authenticator app.') }}
            </h6>
            <button v-b-tooltip.hover class="application-form__copy-button link" :title="qrCode.secret" @click="copy">
                {{ qrCode.secret }}
                <i class="far fa-copy"></i>
            </button>
        </div>
        <hr>
        <h6 class="application-form__title">{{ $t('Verify the code') }}</h6>
        <ui-input
            id="code"
            v-model="formData.token"
            name="code"
            type="text"
            placeholder="123456"
            :label="$t('Code')"
            :disabled="false"
            :maxlength="6"
            :field="v$.formData.token"
        />
    </div>
</template>

<script>
import QR from 'qrcode';
import { useVuelidate } from '@vuelidate/core'
import { helpers, minLength, required } from '@vuelidate/validators';

import UiInput from '@/components/ui/inputs/Input.vue';
import UiButton from '@/components/ui/buttons/Button.vue';

export default {
    name: 'RegisterAuthForm',
    components: { UiInput, UiButton },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            formData: {
                otp_url: '',
                token: ''
            },
            qrCode: {
                secret: '',
                url: ''
            }
        }
    }, 
    validations() {
        return {
            formData: {
                token: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('code') }), required),
                    length: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params), minLength(6)),
                    $autoDirty: true,
                },
            },
        };
    },
    mounted() {
        this.createOTP();
    },
    methods: {
        clearForm() {
            this.formData.token = '';
            this.formData.otp_url = '';
            this.v$.reset();
        },
        validate() {
            this.v$.$touch();
            return !this.v$.$invalid;
        },
        async createOTP() {
            try {
                const { data } = await this.axiosAccount.get('/api/two-factor/generate');
                const { otp_url } = data;

                this.qrCode.url = await QR.toDataURL(otp_url, { color: { dark: '#000', light: '#fff' } });
                this.qrCode.secret = new URL(data.otp_url).searchParams.get('secret');
                this.formData.otp_url = otp_url;
            } catch(error) {
                this.showError(error);
            }
        },
        copy(value) {
            navigator.clipboard.writeText(value);
            this.$toasted.global.successMessage(this.$t('Copied to clipboard'));
        }
    }
}
</script>

<style lang="scss">
.application-form {
    &__title {
        color: #2f2f33;
        font-weight: 500;
        font-size: 14px;
    }

    &__subtitle {
        color: #2f2f33;
        margin-bottom: 16px;
    }

    &__copy-button {
        background-color: transparent;
        border: none;
        border-radius: 4px;
        color: #555555;
        padding: 1px 4px 1px 4px;

        &:hover {
            background-color: $accounts-secondary-100;
        }
    }
}
</style>
