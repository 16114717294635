<template>
    <ui-card
        class="scopes-edit"
        :title="$t('Edit user scopes')"
        :description="$t('Below you can edit the user details and permissions.')"
    >
        <div>
            <template v-if="loading">
                <scope-form-skeleton />
                <hr class="scopes-edit__separator" />
                <permission-selector-skeleton />
            </template>
            <template v-else>
                <button
                    variant="light"
                    class="scopes-edit__back-button mb-3"
                    @click="goBack()"
                >
                    <em class="fas fa-chevron-left mr-1"></em>
                    {{ $t("Back") }}
                </button>
                <scope-form ref="scopeForm" :scope="scope" />
                <div class="text-right" v-if="checkScope('write_scopes')">
                    <ui-button
                        class="mb-0"
                        variant="accent"
                        :loading="updating"
                        @click="updateScope"
                    >
                        {{ $t("Update") }}
                    </ui-button>
                </div>
                <hr class="scopes-edit__separator" />
                <scope-permission-selector
                    :editPermisson="editScope"
                    v-if="scope && checkScope('write_scopes')"
                    :scope="scope"
                    @updatedPermission="handleUpdatedPermission"
                />
                <div class="text-right">
                    <ui-button
                        class="mb-0"
                        variant="secondary"
                        @click="goBack()"
                    >
                        {{ $t("Finish") }}
                    </ui-button>
                </div>
                <button class="floating-button" @click="scrollPage">
                    <span :class="iconArrow"></span>
                </button>
            </template>
        </div>
    </ui-card>
</template>

<script>
import PermissionSelectorSkeleton from "@/components/ui/skeletons/PermissionSelectorSkeleton.vue";
import ScopeForm from "@/components/scopes/Form.vue";
import ScopeFormSkeleton from "@/components/ui/skeletons/ScopeFormSkeleton.vue";
import ScopePermissionSelector from "@/components/scopes/PermissionSelector.vue";
import UiButton from "@/components/ui/buttons/Button.vue";
import UiCard from "@/components/ui/Card.vue";

import scopesMixin from '@/plugins/mixin/scopes.mixin';

export default {
    name: "ScopesEdit",
    components: {
        PermissionSelectorSkeleton,
        ScopeForm,
        ScopeFormSkeleton,
        ScopePermissionSelector,
        UiButton,
        UiCard,
    },
    mixins: [scopesMixin],
    data() {
        return {
            loading: false,
            scope: null,
            updating: false,
            editScope: false,
            iconArrow: "fas fa-arrow-down",
            companyId: null
        };
    },
    async mounted() {
        await this.createToken();

        this.getScope();
    },
    methods: {
        goBack() {
            const { company, scope, site } = this.$route.query;
            const query = { scope, site };

            if (company) {
                this.$router.push({ path: `/profile/companies/${company}/scopes`, query });
                return;
            }

            this.$router.push({ path: '/profile/scopes', query });
        },
        handleUpdatedPermission({ permission }) {
            if (permission.enabled) {
                this.scope.scopes.push(permission.key);
            } else {
                const permission_index = this.scope.scopes.findIndex((item) => item === permission.key);

                if (permission_index >= 0) {
                    this.scope.scopes.splice(permission_index, 1);
                }
            }
        },
        async getScope() {
            try {
                this.loading = true;
                const { id } = this.$route.params;

                const config = {};

                if (this.scopeToken) {
                    config.headers = { Authorization: this.scopeToken };
                }

                const { data } = await this.axiosAccount.get(`/api/scopes/${id}`, config);

                this.scope = data;
            } catch (error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        },
        async updateScope() {
            try {
                this.updating = true;

                if (!this.$refs.scopeForm.validate()) {
                    return;
                }

                const form_data = { ...this.$refs.scopeForm.getFormData() };
                const config = {};

                if (this.scopeToken) {
                    config.headers = { Authorization: this.scopeToken };
                }


                const { data } = await this.axiosAccount.put(`/api/scopes/${this.scope.id}`, form_data, config);

                this.scope.role = data.role;
                this.$toasted.global.successMessage("Scope successfully updated");
            } catch (error) {
                this.showError(error);
            } finally {
                this.updating = false;
            }
        },
        scrollPage() {
            const position = window.scrollY;
            const height = document.body.scrollHeight;

            if (position < 100) {
                window.scrollTo({ top: height, behavior: "smooth" });
                this.iconArrow = "fas fa-arrow-up";
            } else {
                window.scrollTo({ top: 0, behavior: "smooth" });
                this.iconArrow = "fas fa-arrow-down";
            }
        },
    },
};
</script>

<style lang="scss">
.scopes-edit {
&__add-button {
    background-color: transparent;
    border: 0;
    color: $accounts-accent;
    font-weight: 700;
    margin-top: 10px;
    padding: 8px 0;

    span {
    margin-right: 5px;
    }
}

&__back-button {
    background-color: #eeeeee;
    border: none;
    border-radius: 8px;
    padding: 4px 8px;
    transition: 0.15s ease-in-out;

    &:hover {
    background-color: #dbdbdb;
    }
}

&__separator {
    border-color: $accounts-secondary-100;
    margin: 24px 0;
}
}

.floating-button {
position: fixed;
bottom: 20px;
background-color: #4E5F98;
color: white;
border: none;
border-radius: 50%;
width: 50px;
height: 50px;
padding: 15px;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
cursor: pointer;
transition: background-color 0.3s ease-in-out;

&:hover {
    background-color: #0056b3;
}

em {
    font-size: 20px;
}
}
</style>
