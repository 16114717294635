import { getRoute, useLayout } from '@/functions';

export default {
    path: '/',
    component: useLayout('auth'),
    children: [
        getRoute('/login', 'accountsLogin', 'login/login_page', { title: 'Accounts Login' }),
        getRoute('/signup', 'accountsSignup', 'signup/signup_page', { title: 'Accounts Signup' }),
        getRoute('/verify', 'accountsTwoFactorAuth', 'twofactorauth/verify_page', { title: 'Accounts Two Factor Authentication'}),
    ],
};