import i18n from '@/i18n';
import {
    ValidationObserver,
    ValidationProvider,
    extend
} from 'vee-validate';
import {
    confirmed,
    email,
    ext,
    max,
    max_value,
    min,
    min_value,
    regex,
    required,
} from 'vee-validate/dist/rules';
import Vue from 'vue';

const transform = value => {
    return value.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase().replace(/^\w/, c => c.toUpperCase()).replace(/_/g, ' ');
};

extend('required', {
    ...required,
    message: (field) => {
        if (i18n.te(`fields.${field}.required`)) {
            return i18n.t(`fields.${field}.required`);
        }

        return `${i18n.t(transform(field))} ${i18n.t('is required')}`;
    },
});

extend("min_value", {
    ...min_value,
    message: (field, val) => (`${field}, ${i18n.t('Below the minimum')} (${val.min})`),
});

extend("max_value", {
    ...max_value,
    message: (field, val) => (`${field}, ${i18n.t('Exceeds the maximum')} (${val.max})`),
});

extend("max", {
    ...max,
    message: (_, val) => (`${i18n.t('Max')} ${val.length} ${i18n.t('Characters')}`),
});

extend("min", {
    ...min,
    message: (_, val) => (`${i18n.t('Min')} ${val.length} ${i18n.t('Characters')}`),
});

extend("email", {
    ...email,
    message: (_field, _val) => (`${i18n.t('Email invalid')}`),
});

extend("regex", {
    ...regex,
    message: (field, _) => (`${field}, ${i18n.t('Invalid')}`),
});

extend("validDate", {
    validate(value) {
        return (Vue.prototype.$moment().format('x') <= Vue.prototype.$moment(value, 'x').format('x'));
    },
    message: (_field, _val) => (`${i18n.t('Invalid date')}`),
});

extend("validEmail", {
    validate(value) {
        let expression = /^(([^<>()[\]\\.,;+:\s@"]+(\.[^<>()[\]\\.,;+:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return expression.test(String(value).toLowerCase());
    },
    message: (_field, _val) => (`${i18n.t('Email invalid')}`),
});

extend("validPass", {
    validate(value) {
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[[\]@$!%*¡¿?&#=|.(){}:;<>,/~_+-])([A-Za-zd[\]@$!%*¡¿?&#=|.(){}:;<>,/~_+-]|[^ ]){8,25}$/;
        return re.test(value);
    },
    message: (_field, _val) => (`${i18n.t('Password must include upper and lower case letters, numbers, and special characters')}`),
});

extend("ext", {
    ...ext,
});

extend("confirmed", {
    ...confirmed,
    message: (_field, _val) => (`${i18n.t('The field confirmation does not match')}`),
});

extend("passwordConfirmed", {
    ...confirmed,
    message: (_field, _val) => (`${i18n.t('Passwords do not match')}`),
});

extend("emailExists", {
    message: ( _field, _val ) => i18n.t('The email already exists'),
    validate: async (value) => {
        try {
            const config = { auth: { username: value, password: '' } }
            await Vue.axiosAccount.get('/api/accounts/validate/email', config)
            return false
        } catch(error) {
            if (error.response?.status === 404) {
                return true
            }
        }
    }
})

// Register it globally
Vue.component('v-validation', ValidationProvider);
Vue.component('v-observer', ValidationObserver);
