<template>
    <div class="permission-selector-skeleton">
        <b-skeleton class="permission-selector-skeleton__title mb-3" />
        <div class="permission-selector-skeleton__list">
            <div class="permission-selector-skeleton__list-item">
                <b-skeleton class="permission-selector-skeleton__scope-name" />
                <ui-list class="permission-selector-skeleton__permission-list">
                    <ui-list-item class="permission-selector-skeleton">
                        <div class="permission-selector-skeleton__permission-content">
                            <b-skeleton class="permission-selector-skeleton__permission-name" />
                            <b-skeleton class="permission-selector-skeleton__permission-input" type="input" />
                        </div>
                    </ui-list-item>
                    <ui-list-item class="permission-selector-skeleton">
                        <div class="permission-selector-skeleton__permission-content">
                            <b-skeleton class="permission-selector-skeleton__permission-name" />
                            <b-skeleton class="permission-selector-skeleton__permission-input" type="input" />
                        </div>
                    </ui-list-item>
                </ui-list>
            </div>
            <div class="permission-selector-skeleton__list-item">
                <b-skeleton class="permission-selector-skeleton__scope-name" />
                <ui-list class="permission-selector-skeleton__permission-list">
                    <ui-list-item class="permission-selector-skeleton">
                        <div class="permission-selector-skeleton__permission-content">
                            <b-skeleton class="permission-selector-skeleton__permission-name" />
                            <b-skeleton class="permission-selector-skeleton__permission-input" type="input" />
                        </div>
                    </ui-list-item>
                </ui-list>
            </div>
            <div class="permission-selector-skeleton__list-item">
                <b-skeleton class="permission-selector-skeleton__scope-name" />
                <ui-list class="permission-selector-skeleton__permission-list">
                    <ui-list-item class="permission-selector-skeleton">
                        <div class="permission-selector-skeleton__permission-content">
                            <b-skeleton class="permission-selector-skeleton__permission-name" />
                            <b-skeleton class="permission-selector-skeleton__permission-input" type="input" />
                        </div>
                    </ui-list-item>
                    <ui-list-item class="permission-selector-skeleton">
                        <div class="permission-selector-skeleton__permission-content">
                            <b-skeleton class="permission-selector-skeleton__permission-name" />
                            <b-skeleton class="permission-selector-skeleton__permission-input" type="input" />
                        </div>
                    </ui-list-item>
                </ui-list>
            </div>
        </div>
    </div>
</template>

<script>
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';

export default {
    name: 'PermissionSelectorSkeleton',
    components: { UiList, UiListItem },
};
</script>

<style lang="scss">
    .permission-selector-skeleton {
        &__title {
            height: 21px !important;
            max-width: 20%;
        }

        &__input {
            width: 100%;
            height: 38px;
        }

        &__permission-list {
            padding-left: 8px;
            padding-right: 8px;
        }

        &__permission-content {
            align-items: center;
            display: flex;
            gap: 8px;
            justify-content: space-between;
            width: 100%;
        }

        &__list-item {
            margin-bottom: 16px;
            background-color: #f3f3f3;
            padding: 16px;
            border-radius: 8px;
        }

        &__scope-name {
            height: 21px !important;
            max-width: 20%;
        }

        &__permission-name {
            width: 30%;
        }

        &__permission-input {
            width: 20%;
            height: 20px;
        }
    }
</style>
