<template>
    <div class="scope-list">
        <ui-list-group>
            <scope-list-item
                v-for="scope in scopes"
                :key="scope.id"
                :scope="scope"
                @delete="showDeleteModal"
                @edit="$emit('edit', $event)"
            />
        </ui-list-group>
        <ui-modal
            ref="deleteScopeModal"
            centered
            :title="$t('Delete user')"
        >
            <p class="mb-0">{{ $t('Are you sure to remove this user from the organization?') }}</p>
            <template #footer>
                <ui-button variant="secondary" :disabled="deletingScope" @click="$refs.deleteScopeModal.close()">
                    {{ $t('Cancel') }}
                </ui-button>
                <ui-button variant="primary" :loading="deletingScope" @click="deleteScope">
                    {{ $t('Delete') }}
                </ui-button>
            </template>
        </ui-modal>
    </div>
</template>

<script>
import ScopeListItem from '@/components/scopes/ListItem.vue';
import UiButton from '@/components/ui/buttons/Button.vue';
import UiModal from '@/components/ui/modal/Modal.vue';
import UiListGroup from '@/components/ui/list-group/ListGroup.vue';

import scopesMixin from '@/plugins/mixin/scopes.mixin';

export default {
    name: 'ScopeList',
    components: { ScopeListItem, UiButton, UiModal, UiListGroup },
    props: {
        scopes: {
            type: Array,
            default: () => [],
            required: false,
        },
    },
    mixins: [scopesMixin],
    data() {
        return {
            deletingScope: false,
            selectedScope: null,
        };
    },
    async mounted() {
        await this.createToken();
    },
    methods: {
        showDeleteModal(scope_id) {
            this.$refs.deleteScopeModal.show();
            this.selectedScope = scope_id;
        },
        async deleteScope() {
            try {
                this.deletingScope = true;

                const config = {};

                if (this.scopeToken) {
                    config.headers = { Authorization: this.scopeToken };
                }

                await this.axiosAccount.delete(`/api/scopes/${this.selectedScope}`, config);

                this.$refs.deleteScopeModal.close();
                this.$emit('scopeDeleted', this.selectedScope);
                this.selectedScope = null;
                this.$toasted.global.successMessage(this.$t('Scope successfully removed'));
            } catch(error) {
                this.showError();
            } finally {
                this.deletingScope = false;
            }
        },
    }
};
</script>
