<template>
    <div>
        <list-group-skeleton v-if="loading" :rows="3" />
        <list v-else>
            <list-item v-for="auth in auths" :key="auth.name">
                <device-item ref="deviceItem" :auth="auth" @deleteApp="openDeleteDeviceModal" />
            </list-item>
            <list-item v-if="auths.length === 0">
                <p class="web-auth-card__empty-message text-muted">
                    <span class="fas fa-info-circle"></span>
                    {{ $t('You do not yet have devices linked for this authentication method.') }}
                </p>
            </list-item>
        </list>
        <delete-device-modal ref="DeleteDeviceModal" @appDeleted="appDeleted"/>
    </div>
</template>


<script>
import base64url from 'base64url';

import List from '@/components/ui/list/List';
import ListItem from '@/components/ui/list/ListItem';
import DeviceItem from '@/components/authentication/webauthn/DeviceItem.vue';
import ListGroupSkeleton from '@/components/ui/skeletons/ListGroupSkeleton.vue';
import DeleteDeviceModal from '@/components/authentication/webauthn/DeleteDeviceModal.vue';

export default {
    name: 'DeviceList',
    components: {
        List,
        ListItem,
        DeviceItem,
        DeleteDeviceModal,
        ListGroupSkeleton,
    },
    data() {
        return {
            loading: false,
            auths: [],
            challenge: '',
            newDevice: null,
        };
    },
    mounted() {
        this.getAll();
    },
    watch: {
        newDevice(newVal) {
            if(!this.auths.length) {
                this.auths.push(newVal);
                this.$toasted.global.successMessage(this.$t('Device successfully registered'));
                return;
            }

            let devices_name = this.auths?.map(item => item.name);

            if(!devices_name.length){
                this.auths.push(newVal);
                this.$toasted.global.successMessage(this.$t('Device successfully registered'));
            }
            if(!devices_name.includes(newVal.name)) {
                this.auths.push(newVal);
                this.$toasted.global.successMessage(this.$t('Device successfully registered'));
            } else {
                this.$toasted.global.successMessage(this.$t('Device successfully updated')); 
            }
        }
    },
    methods: {
        openDeleteDeviceModal(app) {
            this.$refs.DeleteDeviceModal.openModal(app);
        },
        appDeleted(app) {
            const search_index = this.auths.findIndex(item => item.name === app.name);
            this.auths.splice(search_index, 1);
        },
        async getAll() {
            try {
                this.loading = true;
                const { data } = await this.axiosAccount.get('/api/biometric-auth/auth/webauthn');
                this.auths = data;
            } catch (err) {
                this.$toasted.global.errorMessage('Failed to get devices.', err);
            } finally {
                this.loading = false;
            }
        },
        async getCredentialOptions() {
            const { data } = await this.axiosAccount.get('/api/biometric-auth/register/options');
            const options = data.options;
            this.challenge = options.challenge;

            const credential_options = {
                challenge: Uint8Array.from( options.challenge, c => c.charCodeAt(0)),
                rp: {
                    name: options.rp.name,
                    id: options.rp.id,
                    origin: options.rp.origin
                },
                user: {
                    id: Uint8Array.from( options.user.id, c => c.charCodeAt(0)),
                    name: options.user.name,
                    displayName: options.user.displayName
                },
                pubKeyCredParams: options.pubKeyCredParams,
                authenticatorSelection: options.authenticatorSelection,
                attestation: options.attestationType,
                extensions: options.extensions,
                timeout: 60000
            };
            return credential_options;
        },
        async createCredential() {
            try {
                const credential_options = await this.getCredentialOptions();
                const credential = await navigator.credentials.create({
                    publicKey: credential_options
                });
                return credential;
            } catch (err) {
                console.log(err);
                this.$toasted.global.errorMessage('Failed to create credential.', err);
                return null;
            }
        },
        async addDevice() {
            const credential = await this.createCredential();
            
            if(!credential) {
                return;
            }

            try {
                const format_credential = {
                    id: credential.id,
                    rawId: base64url.encode(credential.rawId),
                    clientDataJSON: base64url.encode(credential.response.clientDataJSON),
                    attestationObject: base64url.encode(credential.response.attestationObject),
                    authenticatorAttachment: credential.authenticatorAttachment,
                    authenticator_data: base64url.encode(credential.response.getAuthenticatorData()),
                    transports: credential.response.getTransports()
                };

                const payload = { 
                    type: 'webauthn',
                    data: {
                        publicKeyCredential: JSON.stringify(format_credential),
                        challenge: this.challenge
                    },
                    device: navigator.userAgent
                };

                const { data } = await this.axiosAccount.post('/api/biometric-auth/register', payload);
                this.newDevice = data.doc;
            } catch (err) {
               this.$toasted.global.errorMessage('Failed to register device.', err); 
            }
        }
    }
};
</script>