import { useView } from '@/functions';

export default {
    path: '/',
    component: useView('accounts/index_page'),
    children: [
        {
            path: 'kyc',
            name: 'kyc',
            component: useView('accounts/kyc/kyc'),
            meta: {
                title: 'KYC Documents'
            }
        },
        {
            path: 'kyc/status/:status',
            name: 'kycStatus',
            component: useView('accounts/kyc/status'),
            meta: {
                title: 'KYC Status'
            }
        },
    ],
};