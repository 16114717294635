<template>
    <div class="ui-card" :class="cardClasses">
        <slot name="header">
            <div v-if="title" class="ui-card__header">
                <div class="ui-card__header-content">
                    <h6 class="ui-card__title">{{ title }}</h6>
                    <p v-if="description" class="ui-card__description">{{ description }}</p>
                </div>
                <div class="ui-card__actions">
                    <slot name="actions"></slot>
                </div>
            </div>
        </slot>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'UiCard',
    props: {
        backgroundVariant: {
            type: String,
            default: null,
            required: false,
        },
        borderVariant: {
            type: String,
            default: null,
            required: false,
        },
        description: {
            type: String,
            default: null,
            required: false,
        },
        helpText: {
            type: String,
            default: null,
            required: false,
        },
        paddingVariant: {
            type: String,
            default: null,
            required: false,
        },
        shadow: {
            type: Boolean,
            default: true,
            required: false,
        },
        title: {
            type: String,
            default: null,
            required: false,
        },
        status: {
            type: String,
            default: null,
            required: false,
        }
    },
    computed: {
        cardClasses() {
            const classes = [];

            if (this.backgroundVariant) {
                classes.push(`ui-card--background-${this.backgroundVariant}`);
            }

            if (this.borderVariant) {
                classes.push(`ui-card--border-${this.borderVariant}`);
            }

            if (this.paddingVariant) {
                classes.push(`ui-card--padding-${this.paddingVariant}`);
            }

            if (this.shadow) {
                classes.push('ui-card--shadow');
            }

            return classes;
        },
    },
};
</script>

<style lang="scss">
.ui-card {
    background-color: $white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 1rem;
    padding: 32px;

    @media (max-width: 768px) {
        padding: 16px;
    }

    &--shadow {
        @media not print {
            box-shadow: 0px 8px 16px rgba(#000000, 0.15);
        }
        @media only print { 
            border: 1px solid $accounts-secondary-200;
        }
    }

    // Background variants
    &--background-white {
        background-color: $white;
    }

    // Border variants
    &--border-secondary {
        border: 1px solid $accounts-secondary-100;
    }

    // Padding variants
    &--padding-sm {
        padding: 16px;
    }

    &__header {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        width: 100%;
    }

    &__header-content {
        width: 100%;
    }

    &__title {
        font-weight: 600;
    }

    &__description {
        color: $accounts-secondary-400;
        font-weight: 400;
        margin-bottom: 0;
    }
}
</style>
