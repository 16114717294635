<template>
    <div class="switch" @click="toggleSwitch">
            <input
                :id="id"
                class="switch-input"
                type="checkbox"
                role="switch"
                v-model="value"
            />
            <span class="switch-label" :class="{ 'checked': value }"></span>
    </div>
</template>

<script>
export default {
    name: 'UiSwitch',
    props: {
        id: {
            type: String,
            default: null,
            required: true,
        },
        name: {
            type: String,
            default: null,
            required: true,
        },
        value: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    data() {
        return {
            checked: this.value,
        };
    },
    methods: {
        toggleSwitch() {
            const value = this.checked;
            this.$emit('change', value);
        },
    },
};
</script>
  
  <style scoped>
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    cursor: pointer;
  }
  
  .switch-input {
    display: none;
  }
  
  .switch-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  
  .switch-label.checked {
    background-color: #007bff;
  }
  
  .switch-label::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: white;
    transition: left 0.3s;
  }
  
  .switch-input:checked + .switch-label::before {
    left: calc(100% - 18px);
  }
  </style>
  