<template>
    <div v-if="invalid && errors.length" class="ui-invalid-feedback">
        {{ errors[0].$message }}
    </div>
</template>

<script>
export default {
    name: 'UiInvalidFeedback',
    props: {
        field: {
            type: Object,
            default: null,
            required: false
        },
    },
    computed: {
        invalid() {
            return this.field?.$error;
        },
        errors() {
            return this.field?.$errors || [];
        },
    }
};
</script>

<style lang="scss">
.ui-invalid-feedback {
    color: $general-error;
    font-size: 0.85rem;
    margin-top: 4px;
}
</style>
