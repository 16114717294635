<template>
    <div class="personal-information-card">
        <h6 class="personal-information-card__title">{{ $t('Personal information') }}</h6>
        <div v-if="loadingAccount" class="text-center py-5">
            <b-spinner />
        </div>
        <profile-form v-else ref="profileForm" :account="account" />
        <div class="d-flex justify-content-end mt-2">
            <ui-button
                variant="accent"
                class="mb-0"
                :loading="updatingProfile"
                @click="handleSubmit"
            >
                {{ $t('Save') }}
            </ui-button>
        </div>
        <vue-recaptcha
            class="w-100"
            ref="profileRecaptcha"
            size="invisible"
            @verify="updateProfile"
            :sitekey="CONST.CAPTCHACODE"
            :loadRecaptchaScript="true"
        />
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

import ProfileForm from '@/components/profile/Form.vue';
import UiButton from '@/components/ui/buttons/Button.vue';

import recaptchaUtils from '@/utils/recaptcha.utils';

export default {
    name: 'PersonalInformationCard',
    components: { ProfileForm, UiButton, VueRecaptcha },
    data() {
        return {
            account: null,
            loadingAccount: true,
            updatingProfile: false,
        };
    },
    mounted() {
        this.getAccount();
    },
    methods: {
        handleSubmit() {
            if (!this.$refs.profileForm.validate()) {
                return;
            }

            if (!recaptchaUtils.isAvailable()) {
                this.updateProfile('');
                return;
            }

            this.$refs.profileRecaptcha.reset();
            this.$refs.profileRecaptcha.execute();
        },
        async getAccount() {
            try {
                this.loadingAccount = true;

                const  { data }  = await this.axiosAccount.get('/api/accounts/me');
                
                this.account = data;
            } catch (error) {
                this.showError(error);
            } finally {
                this.loadingAccount = false;
            }
        },
        async updateProfile(token) {
            try {
                this.updatingProfile = true;

                const form_data = { ...this.$refs.profileForm.formData };

                if (this.account.country) {
                    delete form_data.country;
                }

                const { data } = await this.axiosAccount.put('/api/accounts/me', { ...form_data, token });

                this.account = data;
                this.$toasted.global.successMessage(this.$t('Account information updated successfully'));
            } catch (error) {
                this.showError(error);
            } finally {
                this.updatingProfile = false;
            }
        },
    },
};
</script>

<style lang="scss">
.personal-information-card {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 16px;

    &__title {
        color: $general-black;
        margin-bottom: 16px;
    }
}
</style>
