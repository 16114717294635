<template>
    <div class="ui-phone-input">
        <label :for="id" class="ui-phone-input__label">
            {{ label }}
            <template v-if="isOptional">({{ $t('Optional') }})</template>
        </label>
        <vue-tel-input
            ref="vueTelInput"
            v-model="phone"
            valid-characters-only
            :default-country="defaultCountry"
            :auto-default-country="autoDefaultCountry"
            :disabled="disabled"
            :dropdown-options="{
                disabled: disableDropdown,
                showDialCodeInSelection: true,
                showFlags: true,
                showSearchBox: true,
            }"
            :input-options="{
                autocomplete,
                id,
                name,
                placeholder,
                styleClasses: 'ui-phone-input__input',
            }"
            :style-classes="[
                'ui-phone-input__container',
                {
                    'ui-phone-input__container--valid': field && field.$dirty && !field.$error,
                    'ui-phone-input__container--invalid': field && field.$dirty && field.$error,
                }
            ]"
            :value="value"
            @input="onInput"
        />
        <ui-invalid-feedback :field="field" />
    </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';

import UiInvalidFeedback from '@/components/ui/InvalidFeedback.vue';

export default {
    name: 'PhoneInput',
    components: { UiInvalidFeedback, VueTelInput },
    props: {
        autocomplete: {
            type: String,
            default: null,
            required: false
        },
        autoDefaultCountry: {
            type: Boolean,
            default: true,
            required: false,
        },
        country: {
            type: String,
            default: '',
            required: false,
        },
        debounce: {
            type: Number,
            default: 0,
            required: false,
        },
        defaultCountry: {
            type: String,
            default: '',
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        disableDropdown: {
            type: Boolean,
            default: false,
            required: false,
        },
        field: {
            type: Object,
            default: null,
            required: false,
        },
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        min: {
            type: String,
            required: false,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: null,
            required: false,
        },
        value: {
            type: String,
            default: '',
            required: true,
        },
    },
    data() {
        return { phone: '' };
    },
    computed: {
        isOptional() {
            return this.field && !this.field?.hasOwnProperty('required');
        },
    },
    watch: {
        country(value) {
            if (value) {
                this.$refs.vueTelInput.activeCountryCode = value;
            }
        },
        value(value) {
            if (value) {
                this.phone = value;
            }    
        }
    },
    mounted() {
        if (this.value) {
            this.phone = this.value;
        }
    },
    methods: {
        onInput(number, { countryCallingCode, nationalNumber }) {
            if (countryCallingCode && nationalNumber) {
                this.$emit('input', `${countryCallingCode}${nationalNumber}`);
            }
        },
    },
};
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss">
.ui-phone-input {
    position: relative;

    &__label {
        color: $general-black;
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 4px;
    }

    &__container {
        background-color: $white;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        height: 40px;

        &:focus-within {
            box-shadow: none;
            border-color: #AEE8E9;
            outline: 1px solid #AEE8E9;
        }

        &--valid {
            border-color: $general-success;

            &:focus-within {
                border-color: $general-success;
                box-shadow: none;
            }
        }

        &--invalid {
            border-color: $general-error;

            &:focus-within {
                border-color: $general-error;
                box-shadow: none;
            }
        }
    }

    &__input {
        background-color: transparent;
        background-image: none;
        border: none;
        border-radius: 8px;
        color: $general-black;
        font-size: 14px;
        font-weight: 400;
        height: 100%;
        line-height: 1;
        padding: 4.5px 8px 4.5px 0;
        width: 100%;

        &::placeholder {
            color: $ecart-pay-secondary-300;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active  {
            -webkit-background-clip: text;
        }
    }

    .vti {
        position: initial;

        &__dropdown {            
            position: initial;

            &.open, &:hover {
                background-color: transparent;
            }

            &.disabled {
                background-color: transparent;
                cursor: text !important;

                .vti__dropdown-arrow {
                    display: none;
                }
            }

        }
        
        &__dropdown-list {
            border: 1px solid $accounts-secondary-200;
            border-radius: 8px;
            max-width: 100%;
            padding: 8px;

            &.below {
                top: calc(100% + 5px)
            }
        }

        &__dropdown-item {
            padding: 4.5px 0;
        }

        &__search_box {
            border: 1px solid $accounts-secondary-200;
            border-radius: 8px;
            margin: 0;
            margin-bottom: 5px;
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;
        }
    }
}
</style>
