export function filterScope(token, select_scope) {
    try {
        const parts = token.split('.');

        if (parts.length !== 3) {
            throw new Error(this.$t('The token is not in the expected format.'));
        }

        const decoded_payload = Buffer.from(parts[1], 'base64').toString('utf-8');
        const decoded_data = JSON.parse(decoded_payload);

        if (decoded_data?.scopes) {
            const found = decoded_data.scopes.some(scope => scope.includes(select_scope));
            return found;
        }

        return false;
    } catch (error) {
        return false;
    }
}
