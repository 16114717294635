'use strict';

import Vue from 'vue';
import axios from "axios";
import { encrypt } from '@tendencys/encrypter';

// AXIOS ACCOUNT
const _axiosAccount = axios.create({
    timeout: 10 * 1000
});

_axiosAccount.interceptors.request.use(function(config) {
    const token = Vue.$cookies.get('_atid');
    const language = Vue.$cookies.get('language');

    const excluded_routes = ['/recovery-password'];

    if (!excluded_routes.includes(window.location.pathname)) {
        if (token) {
            config.headers.common['Authorization'] = token;	
        }
    }

    if (language) {
        config.headers.common['Accept-Language'] = language;	
    }
    config.headers.common['Xtid'] = Vue.$cookies.get('_sct');

    if (window !== window.parent) {
        config.headers.common['X-Location'] = window.location;
        config.headers.common['X-IFrame-App'] = encrypt(new URL(window.location).searchParams.get('site_id') || '', process.env.VUE_APP_TENCRYPT);
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

_axiosAccount.interceptors.response.use(undefined, error => {
    const public_routes = ['/login'];
    const excluded_routes = ['/verification/form', '/recovery-password'];
    const statusCode = error?.response?.status;

    if (
        statusCode === 401 &&
        !public_routes.includes(window.location.pathname) &&
        !excluded_routes.includes(window.location.pathname)
    ) {
        Vue.axiosAccount.get('/logout');
        window.location.href = '/login';
    }

    if (error.code === 'ECONNABORTED') {
        Vue.toasted.global.errorMessage('Connection time exceeded, try later');
    }
    return Promise.reject(error);
});

Plugin.install = function (Vue, options) {
    Vue.axiosAccount = _axiosAccount;
    window.axiosAccount = _axiosAccount;
    Object.defineProperties(Vue.prototype, {
        axiosAccount: {
            get() {
                return _axiosAccount;
            }
        },
        $axiosAccount: {
            get() {
                return _axiosAccount;
            }
        }
    });
};

Vue.use(Plugin);
export default Plugin;
