const state = () => ({
    account: null,
});

const actions = {
    async setAccount({ commit }, payload) {        
        commit('SET_ACCOUNT', payload);
    },
};

const mutations = {
    SET_ACCOUNT(state, data) {
        state.account = data;
    },
};

const getters = {
    fullName(state) {
        if (!state.account) return '';
        const firstName = state.account.first_name || '';
        const lastName = state.account.last_name || '';

        return `${firstName} ${lastName}`;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
