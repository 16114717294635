<template>
    <b-modal
        ref="modal"
        modal-class="ui-modal"
        body-class="ui-modal__bootstrap-body"
        content-class="ui-modal__bootstrap-content"
        dialog-class="ui-modal__bootstrap-dialog"
        hide-footer
        hide-header
        :no-fade="!fade"
        :centered="centered"
        :size="size"
        :data-keyboard="false"
        :hide-backdrop="!backdrop"
        @hidden="$emit('hidden', $event)"
        @hide="$emit('hide', $event)"
    >
    <div class="ui-modal__header">
            <slot name="header">
                <h5 class="ui-modal__title">
                    {{ title }}
                </h5>
            </slot>
            <button v-if="!hideHeaderClose" class="ui-modal__close-button" type="button" @click="close()">
                <span class="fas fa-times"></span>
            </button>
        </div>
        <div class="ui-modal__body">
            <slot></slot>
        </div>
        <div v-if="$slots.footer" class="ui-modal__footer">
            <slot name="footer"></slot>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'UiModal',
    props: {
        backdrop: {
            type: [Boolean, String],
            default: true,
            required: false
        },
        centered: {
            type: Boolean,
            default: false,
            required: false,
        },
        fade: {
            type: Boolean,
            default: true,
            required: false,
        },
        hideHeaderClose: {
            type: Boolean,
            default: false,
            required: false,
        },
        title: {
            type: String,
            default: null,
            required: false
        },
        keyboard: {
            type: Boolean,
            default: true,
            required: false,
        },
        size: {
            type: String,
            default: 'md',
            required: false,
        },
    },
    methods: {
        close() {
            this.$refs.modal.hide();
        },
        show() {
            this.$refs.modal.show();
        },
    },
};
</script>

<style lang="scss">
.ui-modal {
    padding: 0 !important;

    @media(max-width: 576px) {
        &.fade .modal-dialog {
            transform: translateY(100%) !important;
            max-width: 100%;
        }

        &.show .modal-dialog {
            transform: translateY(0) !important;
        }
    }

    &__bootstrap-dialog {
        @media(max-width: 576px) {
            align-items: flex-end !important;
            display: flex;
            margin: 0 !important;
            min-height: 100% !important;
        }
    }

    &__bootstrap-content {
        border: none;
        border-radius: 8px;
        box-shadow: 0px 0px 16px rgba(#000000, 0.16);
        max-height: 90vh;

        @media(max-width: 576px) {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }

    &__bootstrap-body {
        padding: 0 !important;
        overflow-y: auto;
    }

    &__header {
        align-items: center;
        background-color: transparent;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        gap: 8px;
        justify-content: space-between;
        padding: 10px 16px;
    }
    
    &__body {
        padding: 16px;
        color: $general-black;
    }

    &__title {
      color: $general-black;
      display: flex;
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 0;
      justify-content: center;
    }

    &__footer {
        border-top: 1px solid #e9e9e9;
        display: flex;
        gap: 16px;
        justify-content: flex-end;
        padding: 8px 16px;
    }

    &__close-button {
        background: none;
        border: none;
        color: $general-black;
        font-size: 14px;
    }
}
</style>
