<template>
    <div class="profile-form">
        <p class="profile-form__subtitle">
            <span class="fas fa-user"></span>
            {{ $t('Basic information') }}
        </p>
        <div class="row">
            <div class="col-md-4 mb-2">
                <ui-input
                    id="firstName"
                    name="firstName"
                    type="text"
                    :label="$t('Name')"
                    :value="account.first_name"
                    :disabled="true"
                />
            </div>
            <div class="col-md-4 mb-2">
                <ui-input
                    id="lastName"
                    name="lastName"
                    type="text"
                    :label="$t('Last name')"
                    :value="account.last_name"
                    :disabled="true"
                />
            </div>
            <div class="col-md-4 mb-2">
                <ui-select
                    v-model="formData.country"
                    :label="$t('Country')"
                    :options="countries"
                    :field="v$.formData.country"
                    option-label="name"
                    option-value="code"
                    :disabled="!!account.country"
                />
            </div>
        </div>
        <p class="profile-form__subtitle mt-2">
            <span class="fas fa-map-marker-alt"></span>
            {{ $t('Contact information') }}
        </p>
        <div class="row">
            <div class="col-md-6 mb-2">
                <ui-input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="email@example.com"
                    :label="$t('Email')"
                    v-model="formData.email"
                    :disabled="false"
                    :field="v$.formData.email"
                />
            </div>
            <div class="col-md-6 mb-2">
                <ui-phone-input
                    ref="phoneInput"
                    id="phone"
                    v-model="formData.phone"
                    name="phone"
                    type="text"
                    disable-dropdown
                    placeholder="55 1254 5678"
                    :label="$t('Phone')"
                    :auto-default-country="!selectedCountry"
                    :default-country="selectedCountry"
                    :country="selectedCountry"
                    :field="v$.formData.phone"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, email } from '@vuelidate/validators';

import UiInput from '@/components/ui/inputs/Input.vue';
import UiSelect from '@/components/ui/inputs/Select.vue';
import UiPhoneInput from '@/components/ui/inputs/Phone.vue';

export default {
    name: 'ProfileForm',
    components: {
        UiInput,
        UiSelect,
        UiPhoneInput,
    },
    props: {
        account: {
            type: Object,
            default: null,
            required: true,
        },
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            countries: [],
            formData: {
                phone: '',
                country: '',
                email: '',
            },
        };
    },
    computed: {
        selectedCountry() {
            return this.account?.country || this.formData?.country || '';
        }
    },
    validations() {
        return {
            formData: {
                email: {
                    required: helpers.withMessage(this.$t('Email required', { field: this.$t('email') }), required),
                    email: helpers.withMessage(this.$t('Email invalid'), email),
                    $autoDirty: true,
                },
                phone: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('phone') }), required),
                },
                country: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('country') }), required),
                },
            },
        };
    },
    mounted() {
        this.getCountries();

        const { phone, country, email } = this.account;

        if (phone) {
            this.formData.phone = phone;
        }

        if (email) {
            this.formData.email = email;
        }

        if (country) {
            this.formData.country = country;
        }
    },
    methods: {
        validate() {
            this.v$.$touch();
            return !this.v$.$invalid;
        },
        async getCountries() {
            this.countries = await this.fnApiGetCountries();
        },
    },
};
</script>

<style lang="scss">
.profile-form {
    &__subtitle {
        color: #7B7B7B;
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 8px;
    }
}
</style>
