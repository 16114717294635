const SET_LANGUAGE = (state, payload) => {
    state.language = payload;
};

const SET_LANGUAGES = (state, payload) => {
    state.languages = payload;
};

const SET_LOADING_SITES = (state, payload) => {
    state.loadingSites = payload;
};

const SET_SITES = (state, payload) => {
    state.sites = [...payload];
};

export default {
    SET_LANGUAGE,
    SET_LANGUAGES,
    SET_LOADING_SITES,
    SET_SITES,
};
