<template>
    <div class="profile-security-index">
        <p class="profile-security-index__text">
            {{ $t('Manage your personal information.') }}
        </p>
        <div class="row">
            <div class="col-md-12">
                <dashboard-profile-card>
                    <two-factor-authentication-card />
                    <web-authn-card />
                </dashboard-profile-card>
            </div>
        </div>
    </div>
</template>

<script>
import DashboardProfileCard from '@/components/profile/DashboardProfileCard.vue';
import TwoFactorAuthenticationCard from '@/components/profile/TwoFactorAuthenticationCard.vue';
import WebAuthnCard from '@/components/profile/WebAuthnCard.vue';

export default {
    name: 'ProfileIndex',
    components: {
        DashboardProfileCard,
        TwoFactorAuthenticationCard,
        WebAuthnCard,
    },
};
</script>
