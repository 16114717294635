import Vue from 'vue';

import { languageCodes } from '@/data';

const getLanguages = async ({ commit }) => {
    const { data } = await Vue.axiosAccount.get('/api/catalogs/languages');

    commit('SET_LANGUAGES', data);
};

const getSites = async ({ commit }) => {
    commit('SET_LOADING_SITES', true);
    
    const { data } = await Vue.axiosAccount.get('/api/sites');

    commit('SET_SITES', data);
    commit('SET_LOADING_SITES', false);
};

const setLanguage = ({ commit }, payload) => {
    let language = 'en-US';

    if (languageCodes.some(item => item === payload)) {
        language = payload;
    }

    Vue.$cookies.set('language', language, '1y');
    commit('SET_LANGUAGE', language);
};

const setInitialLanguage = ({ dispatch }) => {
    let language = Vue.$cookies.get('language');

    if (!language) {
      const navigator_language = window.navigator.userLanguage || window.navigator.language;
      language = navigator_language;
    }

    dispatch('setLanguage', language);
};

export default {
    getLanguages,
    getSites,
    setLanguage,
    setInitialLanguage,
};
