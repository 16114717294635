<template>
    <div class="list-item">
        <slot>
            <div class="list-item__title">
                <slot name="title">
                    {{ title }}
                </slot>
            </div>
            <div v-if="img">
                <slot name="img">
                    <img :src="img" :alt="title" />
                </slot>
            </div>
            <div class="list-item__text">
                <slot name="text">
                    {{ text }}
                </slot>
            </div>
        </slot>
    </div>
</template>

<script>
export default {
    name: 'ListItem',
    props: {
        title: {
            type: String,
            default: null,
            required: false,
        },
        text: {
            type: [String, Number],
            default: null,
            required: false,
        },
        img: {
            type: String,
            default: null,
            required: false,
        }
    },
};
</script>

<style lang="scss" scoped>
.list-item {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0px;

    @media (max-width: 540px) {
        display: initial;
    }
    
    &:not(:last-child) {
        border-bottom: 1px solid $ecart-pay-secondary-100;
    }

    &__title {
        color: $ecart-pay-secondary-500;
        font-weight: 500;
        width: 150px;
        max-width: 100%;

        @media (max-width: 540px) {
            width: 100%;
        }

        &:first-letter {
            text-transform: uppercase;
        }
    }
    
    &__text {
        font-weight: 500;
        width: calc(100% - 150px);

        @media (max-width: 540px) {
            width: 100%;
        }
    }
}
</style>
