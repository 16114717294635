<template>
    <div class="profile-scopes-index">
        <ui-card
            class="scopes-index"
            :title="$t('My organizations')"
            :description="$t('Manage the users who have access to your organizations.')"
        >
            <div>
                <list-group-skeleton v-if="loadingCompanies" :rows="filters.companies.limit" />
                <template v-else>
                    <empty-state
                        v-if="!companies.length"
                        class="profile-scopes-index__empty-state"
                        :text="$t('No organizations were found registered in your account.')"
                        img="empty-box.svg"
                    />
                    <company-list v-else :companies="companies" />
                    <div class="d-flex justify-content-center mt-3">
                        <ui-pagination v-model="filters.companies.page" :total-pages="totalPages.companies" />
                    </div>
                </template>
            </div>
        </ui-card>
        <ui-card
            class="scopes-index"
            :title="$t('External organizations')"
            :description="$t('Manage users from external organizations to which you have access.')"
        >
            <div>
                <list-group-skeleton v-if="loadingExternalCompanies" :rows="filters.externalCompanies.limitExternal" />
                <template v-else>
                    <empty-state
                        v-if="!externalCompanies.length"
                        class="profile-scopes-index__empty-state"
                        :text="$t('You do not yet have access to any external organization.')"
                        img="empty-box.svg"
                    />
                    <company-list v-else :companies="externalCompanies" external />
                    <div class="d-flex justify-content-center mt-3">
                        <ui-pagination v-model="filters.externalCompanies.pageExternal" :total-pages="totalPages.externalCompanies" />
                    </div>
                </template>
            </div>
        </ui-card>
    </div>
</template>

<script>
import EmptyState from '@/components/ui/EmptyState';
import ListGroupSkeleton from '@/components/ui/skeletons/ListGroupSkeleton';
import UiCard from '@/components/ui/Card.vue';
import UiPagination from '@/components/ui/pagination/Pagination';
import CompanyList from '@/components/scopes/CompanyList.vue';

import tablesMixin from '@/mixins/tables.mixin';
import iframeMixin from '@/plugins/mixin/iframe.mixin';
import supportChatUtils from '@/utils/support-chat.utils';

export default {
    name: 'ScopesIndex',
    components: {
        EmptyState,
        ListGroupSkeleton,
        UiCard,
        UiPagination,
        CompanyList,
    },
    mixins: [tablesMixin, iframeMixin],
    data() {
        return {
            externalCompanies: [],
            companies: [],
            filters: {
                companies: {
                    limit: 4,
                    page: 1,
                },
                externalCompanies: {
                    limitExternal: 4,
                    pageExternal: 1,
                },
            },
            loadingCompanies: false,
            loadingExternalCompanies: false,
            totalPages: {
                companies: 1,
                externalCompanies: 1,
            },
        };
    },
    watch: {
        'filters.companies': {
            immediate: false,
            handler(value) {
                this.getCompanies();
                this.replaceQueryParams(value);
            },
            deep: true,
        },
        'filters.externalCompanies': {
            immediate: false,
            handler(value) {
                this.getExternalCompanies();
                this.replaceQueryParams(value);
            },
            deep: true,
        },
    },
    mounted() {
        if (this.itsAnIframe) {
            supportChatUtils.hideChat();
        }

        this.filters.companies = this.getFiltersByQueryParams(this.filters.companies);
        this.filters.externalCompanies = this.getFiltersByQueryParams(this.filters.externalCompanies);
    },
    methods: {
        async getCompanies() {
            try {
                this.loadingCompanies = true;

                const params = { ...this.filters.companies };
                const { data } = await this.axiosAccount.get('/api/companies', { params });

                this.companies = data.docs;
                this.totalPages.companies = data.pages;
            } catch (error) {
                this.showError(error);
            } finally {
                this.loadingCompanies = false;
            }
        },
        async getExternalCompanies() {
            try {
                this.loadingExternalCompanies = true;

                const params = {
                    ...this.filters.externalCompanies,
                    limit: this.filters.externalCompanies.limitExternal,
                    page: this.filters.externalCompanies.pageExternal,
                };

                if (this.$route?.query?.site) {
                    params.site_id = this.$route.query.site;
                }

                delete params.limitExternal;
                delete params.pageExternal;

                const { data }  = await this.axiosAccount.get('/api/scopes/companies', { params });

                this.externalCompanies = data.docs;
                this.totalPages.externalCompanies = data.pages;
            } catch (error) {
                this.showError(error);
            } finally {
                this.loadingExternalCompanies = false;
            }
        },
    },
};
</script>

<style lang="scss">
.scopes-index {
    &__empty-state {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    &__add-button {
        background-color: transparent;
        border: 0;
        color: $accounts-primary-600;
        font-weight: 700;
        margin-top: 10px;
        padding: 8px 0;

        span {
            margin-right: 5px;
        }
    }
}
</style>
