import Vue from 'vue';

const addAccount = ({ commit, state }, payload) => {
    const account_index = state.accounts.findIndex(item => item.email === payload.email);

    if (account_index >= 0) {
        let accounts = [...state.accounts];
        accounts.splice(account_index, 1);
        accounts = [payload, ...accounts];

        commit('SET_ACCOUNTS', accounts);
    } else {
        commit('ADD_ACCOUNT', payload);
    }

    Vue.$cookies.set('_acli', Buffer.from(JSON.stringify(state.accounts)).toString('base64'), '7d');
};

const deleteAccount = ({ commit, state }, payload) => {
    commit('DELETE_ACCOUNT', payload);
    Vue.$cookies.set('_acli', Buffer.from(JSON.stringify(state.accounts)).toString('base64'), '7d');
};

const setAccounts = ({ commit }, payload) => {
    commit('SET_ACCOUNTS', payload);
};

export default {
    addAccount,
    deleteAccount,
    setAccounts,
};
