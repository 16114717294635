<template>
    <div class="empty-state">
        <img
            alt="Not found"
            class="empty-state__img"
            :class="`empty-state__img--${imgSize}`"
            :src="require(`@/assets/images/empty-states/${img}`)"
        />
        <h5 v-if="title" class="empty-state__title" :class="{ 'mb-0': !text }">{{ title }}</h5>
        <p v-if="text" class="empty-state__text">{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: 'EmptyState',
    props: {
        img: {
            type: String,
            default: 'empty-box.svg',
            required: false,
        },
        imgSize: {
            type: String,
            default: 'sm',
            required: false,
        },
        text: {
            type: String,
            default: null,
            required: false,
        },
        title: {
            type: String,
            default: null,
            required: false,
        },        
    },
};
</script>

<style lang="scss">
.empty-state {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &__img {
        height: auto;
        max-width: 100%;
        width: 100%;
        margin-bottom: 20px;

        &--xs {
            max-width: 75px;
        }

        &--sm {
            max-width: 150px;
        }

        &--md {
            max-width: 250px;
        }
    }

    &__title {
        color: $accounts-secondary;
        font-size: 1.1rem;
        font-weight: 600;
    }

    &__text {
        color: $ecart-pay-secondary-600;
        font-size: 0.95rem;
        font-weight: 400;
    }
}
</style>