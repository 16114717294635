<template>
    <ui-modal 
        ref="deleteApp"
        :title="$t('Delete device')"
        centered
        @hidden="closeModal()"
    >
        <p>{{ $t('Are you sure you want to delete this device?') }}</p>
        <p>{{ $t('Please note that by doing so, you will lose an additional layer of security on your account.') }}</p>
        <template #footer>
            <ui-button variant="secondary" :disabled="loading" @click="$refs.deleteApp.close()">
                {{ $t('Cancel') }}
            </ui-button>
            <ui-button variant="primary" :loading="loading" @click="deleteApp">
                {{ $t('Delete') }}
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button.vue';
import UiModal from '@/components/ui/modal/Modal.vue';

export default {
    name: 'DeleteDeviceModal',
    components: { UiButton, UiModal },
    data() {
        return {
            loading: false,
            app: {}
        }
    },
    methods: {
        openModal(app) {
            this.app = app;
            this.$refs.deleteApp.show();
        },
        closeModal() {
            this.$refs.deleteApp.close();
        },
        async deleteApp() {
            try {
                this.loading = true;
                await this.axiosAccount.delete(`/api/biometric-auth/remove/${this.app.id}`);
                this.$emit('appDeleted', this.app);
                this.$toasted.global.successMessage(this.$t('Device removed successfully'));
            } catch (err) {
                this.$toasted.global.errorMessage(this.$t('An error occurred while removing the device, please try again'));
            } finally {
                this.loading = false;
                this.closeModal();
            }
        }
    }
}
</script>