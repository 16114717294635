import ProfileIndex from '@/views/dashboard/profile/index.vue';
import ProfileSecurityIndex from '@/views/dashboard/profile/security/index.vue';

import { useView, useLayout } from '@/functions';

export default {
    path: '/dashboard',
    component: useLayout('dashboard'),
    children: [
        {
            path: '/',
            name: 'dashboard',
            component: useView('dashboard/home'),
            meta: {
                title: 'Home',
            },
        },
        {
            path: 'profile',
            name: 'dashboardProfile',
            component: ProfileIndex,
            meta: {
                title: 'Profile',
            },
        },
        {
            path: 'profile/security',
            name: 'dashboardProfileSecurity',
            component: ProfileSecurityIndex,
            meta: {
                title: 'Profile security',
            },
        },
    ],
};
